import { Fragment, ReactElement, useEffect, useState } from 'react';
import css from '../css/MessagesTable.module.css';

// import types
import type IOracleMessageForPresentation from '../interfaces/IParsedOracleMessage';

// import components
import TableCell from './TableCell';

/**
 * Represents the props expected by the MessagesTable component
 *
 * @typedef {Object}                            IProps
 *
 * @prop    {Array<IOracleMessageForPresentation>}       oracleMessages       array of messages to be displayed in the table
 * @prop    {{ () : void }}                     onClickLoadMore	     callback triggered when the user clicks on load more
 */
interface IMessagesTableProps
{
	oracleMessages: Array<IOracleMessageForPresentation>;
	onClickLoadMore: { () : void };
	oraclePublicKey: string;
}

/**
 * Component to render content in table
 *
 * @param   {IMessagesTableProps}                props - input props for the component
 *
 * @returns {ReactElement<IMessagesTableProps>}  react component
 */
const MessagesTable = function(props: IMessagesTableProps): ReactElement<IMessagesTableProps>
{
	const [ isLoadMoreButtonVisible, setIsLoadMoreButtonVisible ] = useState<boolean>(false);

	/**
	 * finds out if the load more button in the table needs to be shown each time more oracles are loaded
	 */
	useEffect(function handleLoadMoreButtonVisibility()
	{
		// Find out if load more button has to be shown in= the table component
		// We can decide to show it only if there is data in the table (default is false)
		if(props.oracleMessages.length > 0)
		{
			// pick the price sequence of the oldest row (i.e. row at the bottom of the table)
			const messageSequence = Number(props.oracleMessages[props.oracleMessages.length - 1].messageSequence);

			// if the messageSequence is greater than 1, that implies that all of the messages are yet to be loaded
			if(messageSequence > 1)
			{
				setIsLoadMoreButtonVisible(true);
			}
			else
			{
				setIsLoadMoreButtonVisible(false);
			}
		}
	}, [ props.oracleMessages.length ]);

	return (
		<Fragment>
			<table className={css.messageTable}>
				<thead className={css.oracleMessageTableHead}>
					<tr>
						{/* this column contains message verification status icon and doesn't need content in header */}
						<th style={{ width: '2rem' }} ></th>
						<th>
							<span>Message</span>
						</th>
						<th>
							<span>Timestamp</span>
						</th>
						<th>
							<span>Price</span>
						</th>
						<th className={css.hideOnSmallScreen}>
							<span>Content</span>
						</th>
					</tr>
				</thead>

				<tbody>
					{props.oracleMessages.map((oracleMessage, rowIndex) => (
						<tr key={rowIndex} className={oracleMessage.isValidSignature ? '' : css.invalidSignedMessage}>
							<TableCell
								content=''
								isTruncatedContent={false}
								primaryIcon={oracleMessage.isValidSignature ? '✔' : '⨯'}
								primaryIconColor={oracleMessage.isValidSignature ? 'var(--teal-lighter)' : 'var(--red)'}
								tooltipText={oracleMessage.isValidSignature ? 'Valid Signature' : 'Invalid Signature'}
							/>
							<TableCell content={String(oracleMessage.messageSequence)} linkTo={`/oracles/${props.oraclePublicKey}/${oracleMessage.messageSequence}`} />
							<TableCell content={oracleMessage.utcMessageTime} />
							<TableCell content={String(oracleMessage.scaledPriceByFactor)} />
							<TableCell
								content={String(oracleMessage.message)}
								linkTo={`/oracles/${props.oraclePublicKey}/${oracleMessage.messageSequence}`}
								hideOnSmallScreen
							/>
						</tr>
					))}
					<tr>
						{isLoadMoreButtonVisible && <td colSpan={5} style={{ paddingRight: 'var(--space-normal)' }}>
							<form>
								<button
									className={css.loadMoreButton}
									data-testid='loadMoreButton'
									onClick={props.onClickLoadMore}
									type="button"
								>Load More</button>
							</form>
						</td>}
					</tr>
				</tbody>
			</table>
		</Fragment>
	);
};

export default MessagesTable;
