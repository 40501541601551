// import interfaces
import type IAction from '../interfaces/IAction';
import type IGlobalState from '../interfaces/IGlobalState';

import React, { createContext, ReactElement, useReducer } from 'react';
import { GET_ORACLES_MESSAGE_COUNT_SUCCESS, GET_ORACLES_METADATA_SUCCESS, GET_ORACLES_SUCCESS } from './types';

const initialState: IGlobalState = { oracles: [], oraclePublicKeyAndMetadataMap: {}, oracleMessageCount: 0 };

interface GlobalContext
{
	state: typeof initialState;
	dispatch: React.Dispatch<IAction>;
}

const globalStore = createContext<GlobalContext>({
	state: initialState,
	dispatch: () => null,
});
const { Provider } = globalStore;

const StateProvider = function(props: any): ReactElement
{
	// set up the store to use a reducer that updates the store when a dispatched action is handled
	const [ state, dispatch ] = useReducer((globalState: typeof initialState, action: IAction) =>
	{
		switch(action.type)
		{
			case GET_ORACLES_SUCCESS:
				return {
					...globalState,
					oracles: action.payload.oracles,
				};

			case GET_ORACLES_METADATA_SUCCESS:
				return {
					...globalState,
					oraclePublicKeyAndMetadataMap: action.payload.oraclePublicKeyAndMetadataMap,
				};
			case GET_ORACLES_MESSAGE_COUNT_SUCCESS:
				return {
					...globalState,
					oracleMessageCount: action.payload.messageCount,
				};
			default:
				throw new Error();
		}
	}, initialState);

	return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
};

export { globalStore, StateProvider };
