// import types
import type IOracleSummary from '../interfaces/IOracleSummary';
import type IHexSignedOracleMessage from '../interfaces/IHexSignedOracleMessage';
import type IMetricsLabelAndValue from '../interfaces/IMetricsLabelAndValue';
import type IOracleMessageForPresentation from '../interfaces/IParsedOracleMessage';
import type IOracleMetadata from '../interfaces/IOracleMetadata';
import type IRecoveryProgress from '../interfaces/IRecoveryProgress';
import type IRecoveryProgressPresentation from '../interfaces/IRecoveryProgressPresentation';
import type { IOracleMetadataKeys, IOracleProtocolMetadataTypeKeys, IOracleSourceUnitCode } from '../interfaces/derivedTypes';
import type IRawPriceGraphDataPoint from '../interfaces/IRawPriceGraphDataPoint';
import type ILatestMessageAndRecoveryProgress from '../interfaces/ILatestMessageAndRecoveryProgress';
import type IPriceChangePresentation from '../interfaces/IPriceChangePresentation';
import type IOracleMessagesTableDataFilters from '../interfaces/IOracleMessagesTableDataFilters';
import type IOracleWithMessageMetrics from '../interfaces/IOracleWithMessageMetrics';
import type IPublicKeyAndOracleDataMap from '../interfaces/IPublicKeyAndOracleDataMap';

// import utils
import { getRelayStatus, getOracleMessages, getRecoveryProgress, getOracleMetadata, getOracles } from './apis';
import { OracleData } from './priceOracle/OracleData';
import { hexToBin } from '@bitauth/libauth';
import { getPriceGraphPointsQueryParams, getPriceWithDecimalPrecision } from './misc';
import OracleConstants from '../constants/OracleConstants';
import moment from 'moment';
import { OracleProtocol } from './priceOracle/protocol';

/**
 * transforms epoch to date to human readable local time YYYY-MM-DD or optionally YYYY-MM-DD HH:M:S format
 *
 * @param   {number}     epoch           timestamp in seconds
 * @param   {boolean}    includeTime     includes the time part in the returned string if true
 *
 * @returns {string}                     date in YYYY-MM-DD format
 */
export const transformEpochToDateTime = function(epoch: number, includeTime: boolean = false): string
{
	const inputDate = new Date(epoch * 1000);
	const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
	const year = inputDate.getFullYear();
	const month = months[inputDate.getMonth()];
	const date = inputDate.getDate();

	let dateString = `${year}-${month}-${date}`;

	if(includeTime)
	{
		const hours = inputDate.getHours();
		const minutes = inputDate.getMinutes();
		const seconds = inputDate.getSeconds();

		dateString += ` ${hours}:${minutes}:${seconds}`;
	}

	return dateString;
};

/**
 * Transform price message in format used to visualize data in UI
 *
 * @param   {IHexSignedOracleMessage}       oracleMessage       oracle message fetched from the backend that need to be transformed
 * @param   {number}                        attestationScaling  the value by which the oracle price is divided with for presentation purpose
 *
 * @returns {Promise<IOracleMessageForPresentation>}            formatted oracle message to be used for presentation on the UI
 */
export const formatPriceMessageForPresentation = async function(
	oracleMessage: IHexSignedOracleMessage,
	attestationScaling: number,
): Promise<IOracleMessageForPresentation>
{
	// parse the price message
	const parsedPriceMessage = await OracleData.parsePriceMessage(hexToBin(oracleMessage.message));

	// check if the message signature is valid (if the message was signed using the private key pair of the given public key)
	const { message, signature, publicKey } = oracleMessage;
	const isValidSignature = await OracleData.verifyMessageSignature(hexToBin(message), hexToBin(signature), hexToBin(publicKey));

	const priceMessageForPresentation =
		{
			scaledPriceByFactor: getPriceWithDecimalPrecision(parsedPriceMessage.priceValue, attestationScaling),
			priceRaw: parsedPriceMessage.priceValue,
			messageTimestamp: parsedPriceMessage.messageTimestamp,
			utcMessageTime: moment.unix(parsedPriceMessage.messageTimestamp).utc()
				.format('YYYY-MM-DD, HH:mm:ss'),
			messageSequence: parsedPriceMessage.messageSequence,
			message: oracleMessage.message,
			signature: oracleMessage.signature,
			isValidSignature,
			priceSequence: parsedPriceMessage.priceSequence,
			metadataContent: null,
			metadataType: null,
			metadataName: null,
		};

	return priceMessageForPresentation;
};

/**
 * Transform oracle metadata message in format used to visualize data in UI
 *
 * @param   {IHexSignedOracleMessage}       oracleMessage       oracle message fetched from the backend that need to be transformed
 *
 * @returns {Promise<IOracleMessageForPresentation>}            formatted oracle message to be used for presentation on the UI
 */
export const formatMetadataMessageForPresentation = async function(oracleMessage: IHexSignedOracleMessage):Promise<IOracleMessageForPresentation>
{
	// parse the metadata message
	const parsedMetadataMessage = await OracleData.parseMetadataMessage(hexToBin(oracleMessage.message));

	// destructure metadata message attributes
	const { messageTimestamp, messageSequence, metadataType, metadataContent } = parsedMetadataMessage;

	// check if the message signature is valid (if the message was signed using the private key pair of the given public key)
	const { message, signature, publicKey } = oracleMessage;
	const isValidSignature = await OracleData.verifyMessageSignature(hexToBin(message), hexToBin(signature), hexToBin(publicKey));

	const formattedMetadataMessage =
		{
			messageTimestamp,
			utcMessageTime: new Date(parsedMetadataMessage.messageTimestamp * 1000).toLocaleString(),
			messageSequence,
			message: oracleMessage.message,
			signature: oracleMessage.signature,
			isValidSignature,
			metadataType,
			metadataContent,
			priceRaw: null,
			priceSequence: null,
			scaledPriceByFactor: null,
			metadataName: OracleProtocol.METADATA_TYPES[String(metadataType) as IOracleProtocolMetadataTypeKeys].name,
		};

	return formattedMetadataMessage;
};

/**
 * transform oracle messages in format used to visualize data in table
 *
 * @param   {Array<IHexSignedOracleMessage>}    oracleMessages      array of oracle messages fetched from the backend that need to be transformed
 * @param   {number}                            oraclePriceFactor   the value by which the oracle price is divided with for presentation purpose
 *
 * @returns {Promise<Array<IOracleMessageForPresentation>>} an array of oracle messages to be used for presentation on the UI
 */
export const formatOracleMessagesForTableRows = async function(
	oracleMessages: Array<IHexSignedOracleMessage>,
	oraclePriceFactor: number,
): Promise<Array<IOracleMessageForPresentation>>
{
	const formattedOracleMessages: Array<IOracleMessageForPresentation> = [];

	for(const oracleMessage of oracleMessages)
	{
		const parsedOracleMessage = await OracleData.parseOracleMessage(hexToBin(oracleMessage.message));

		if(parsedOracleMessage.dataSequenceOrType > 0)
		{
			const priceMessage = await formatPriceMessageForPresentation(oracleMessage, oraclePriceFactor);
			formattedOracleMessages.push(priceMessage);
		}
		else
		{
			const metadataMessage = await formatMetadataMessageForPresentation(oracleMessage);
			formattedOracleMessages.push(metadataMessage);
		}
	}

	return formattedOracleMessages;
};

/**
 * fetches counters and metrics from the backend
 *
 * @returns {Promise<Array<IMetricsLabelAndValue>>}    list counter and metrics for oracle
 *
 * @throws  {Error}                                    if API call fails for any reason
 */
export const getRelayMetricsLabelAndValues = async function(): Promise<Array<IMetricsLabelAndValue>>
{
	// get relay status containing counter and metrics from the backend
	const { metrics, counters } = await getRelayStatus();

	// prepare a map of counter keys and corresponding labels with which the counter value needs to be shown on the UI
	const counterAndLabelMap = {
		priceMessagesCount: 'Price Messages Count',
		restApiRequestsCount: 'Rest Api Requests Count',
		messageRequestsCount: 'Message Requests Count',
		relayMessagesConsumed: 'Relay Messages Consumed',
		relayMessagesPublished: 'Relayed Messages',
		broadcastMessagesConsumed: 'Broadcast Messages',
		broadcastMessagesPublished: 'Requested Messages',
	};

	// generate an array of label and value objects
	const counterKeys = Object.keys(counterAndLabelMap) as Array<keyof typeof counterAndLabelMap>;
	const counterLabelAndValues: any = counterKeys.map((counterKey) => (
		{
			label: [ counterAndLabelMap[counterKey] ],
			value: counters[counterKey],
		}
	));

	// prepare a map of metric keys and corresponding labels with which the counter value needs to be shown on the UI
	const metricAndLabelMap = {
		serverStartTime: 'Server Start Time',
	};

	// generate an array of label and value objects
	const metricKeys = Object.keys(metricAndLabelMap) as Array<keyof typeof metricAndLabelMap>;
	const metricLabelAndValues: any = metricKeys.map((metricKey) => (
		{
			label: [ metricAndLabelMap[metricKey] ],
			// transform to human readable time if it's serverStartTime
			value: metricAndLabelMap[metricKey] === metricAndLabelMap.serverStartTime ? new Date(metrics[metricKey] * 1000).toLocaleString() : metrics[metricKey],
		}
	));

	// merge the counter and metric label and values in one array
	const mergedCountersAndLabels = [ ...counterLabelAndValues, ...metricLabelAndValues ];

	return mergedCountersAndLabels;
};

/**
 * Returns oracle messages data in format that is used to visualize data in UI table
 *
 * @param    {string}       attestationScaling     the value by which the oracle price is divided with for presentation purpose
 * @param    {string}       publicKey              oracle public for which messages need to be fetched
 * @param    {string}       count                  number of messages to be fetched
 * @param    {string}       minMessageSequence     minimum message sequence to filter oracle messages
 * @param    {number}       maxMessageSequence     maximum message sequence to filter oracle messages
 *
 * @returns  {Array<Array<(string | number)>>}    array of rows containing array of column values
 */
export const getOracleMessagesTableData = async function(
	attestationScaling: number,
	filters: IOracleMessagesTableDataFilters,
): Promise<Array<IOracleMessageForPresentation>>
{
	// get price messages data to be shown in table (price messages start from dataSequence value of 1)
	const priceMessages = await getOracleMessages({ ...filters });

	// format the oracle messages in the format that the table component accepts
	const parsedPriceMessages = await formatOracleMessagesForTableRows(priceMessages, attestationScaling);

	return parsedPriceMessages;
};

/**
 * Gets appropriate icon and tooltip text based on recovery progress
 *
 * @param   {IRecoveryProgress}   IRecoveryProgress     object containing the recovery progress attributes
 *
 * @returns {IRecoveryProgressPresentation}             object containing icon and text representing the current recovery status
 */
export const getRecoveryProgressPresentationAttributes = (recoveryProgress: IRecoveryProgress): IRecoveryProgressPresentation =>
{
	// find out the age of the most recent message timestamp in minutes
	const millisecondsDifference = new Date().valueOf() - recoveryProgress.maxMessageTimestamp * 1000;

	// find out the age of the most recent message timestamp in relative time
	const humanReadableAge = moment(recoveryProgress.maxMessageTimestamp * 1000).fromNow();

	const latestMessageAgeInMinutes = Math.round((millisecondsDifference / 1000) / 60);

	// define the age in number of minutes that we consider as 'recent'
	const recentAgeThreshold = 5;

	const isRecentMessage = latestMessageAgeInMinutes <= recentAgeThreshold;
	const isRelayInSyncWithOracle = recoveryProgress.recoveryProgressPercent === 100;

	// initialize recoveryProgressPresentation with default value assuming that latest timestamp is not recent
	let recoveryProgressPresentation = { icon: '--invalid', iconColor: 'yellow', title: `Latest timestamp was ${humanReadableAge}`, messagesCount: 0 };

	if(isRecentMessage && isRelayInSyncWithOracle)
	{
		recoveryProgressPresentation = { ...recoveryProgress, icon: '--valid', iconColor: 'var(--logo-blue)', title: 'Latest timestamp is recent and we have 100% of the messages' };
	}

	if(isRecentMessage && !isRelayInSyncWithOracle)
	{
		recoveryProgressPresentation = { ...recoveryProgress, icon: '--info', iconColor: 'grey', title: `Recovery is in progress, currently at ${recoveryProgress.recoveryProgressPercent}` };
	}

	recoveryProgressPresentation.messagesCount = recoveryProgress?.messagesCount || 0;

	return recoveryProgressPresentation;
};

/**
 * Calculates difference percentage between old and new price.
 *
 * @param   {Array<IRawPriceGraphDataPoint>}  graphPoints   array of price and time period between a time range
 *                                                  1st and last points can be used to find percentage difference here
 *
 * @returns percentageDifference                    difference in old and new price in percentage
 */
export const getPercentageChange = function(graphPoints: Array<IRawPriceGraphDataPoint>): number
{
	// extract new price variables from graph points
	const oldPrice = graphPoints[0].averagePrice;
	const newPrice = graphPoints[graphPoints.length - 1].averagePrice;

	// find the difference between prices
	const priceDifference = newPrice - oldPrice;

	// calculate the difference percentage between old and new price points
	const percentageDifference = ((priceDifference / oldPrice) * 100);

	return percentageDifference;
};

/**
* Returns the color in which percentage change is to be shown based on the argument value
*
* @param    {number}   percentDifferenceBetweenOldAndNewPrice   percentage change in price between the first and last point plotted on the graph
*
* @returns  {string}   hex value of the color
*/
export const getPercentageChangeColor = (percentDifferenceBetweenOldAndNewPrice: number): string =>
{
	// initialize all possible colors that the text should be shown in
	const darkGreen = '#369133';
	const logoBlue = '#38B6FF';
	const logoPink = '#FF66C4';
	const darkRed = '#ff0000';
	let colorToReturn = '';

	// use dark red when the price change percentage is less than -2% (exclusive)
	if(percentDifferenceBetweenOldAndNewPrice < -2)
	{
		colorToReturn = darkRed;
	}
	// use logo pink when price change percentage is between -2% (inclusive) and 0 (exclusive)
	else if(percentDifferenceBetweenOldAndNewPrice >= -2 && percentDifferenceBetweenOldAndNewPrice < 0)
	{
		colorToReturn = logoPink;
	}
	// use logo blue when the price change percentage is between 0% (inclusive) and 2% (inclusive)
	else if(percentDifferenceBetweenOldAndNewPrice >= 0 && percentDifferenceBetweenOldAndNewPrice <= 2)
	{
		colorToReturn = logoBlue;
	}
	// use dark green when price change percentage is greater than 2% (exclusive)
	else if(percentDifferenceBetweenOldAndNewPrice > 2)
	{
		colorToReturn = darkGreen;
	}

	return colorToReturn;
};

export const getPublicKeyAndPriceMessageWithRecoveryProgressMap = async function(
	oraclePublicKeys: Array<string>,
): Promise<Record<string, ILatestMessageAndRecoveryProgress>>
{
	// initialize arrays to store the promises to get the oracle messages, recovery progress details and, price graph points
	const promisesToGetPriceMessages : Array<Promise<Array<IHexSignedOracleMessage>>> = [];
	const promisesToGetRecoveryProgress : Array<Promise<IRecoveryProgress>> = [];

	// loop through all of the metadata properties of the oracle metadata
	for(const publicKey of oraclePublicKeys)
	{
		// TODO: handle api fails gracefully
		// add promises that perform api calls in an array to run them in parallel (price messages start from dataSequence value of 1)
		promisesToGetPriceMessages.push(getOracleMessages({ publicKey, count: 1, minDataSequence: 1 }));
		promisesToGetRecoveryProgress.push(getRecoveryProgress(publicKey));
	}

	// resolve promises to make API calls in parallel rather than doing api calls sequentially
	const priceMessages = await Promise.all(promisesToGetPriceMessages);
	const recoveryProgress: IRecoveryProgress[] = await Promise.all(promisesToGetRecoveryProgress);

	const publicKeyAndDataMap: Record<string, ILatestMessageAndRecoveryProgress> = {};

	oraclePublicKeys.forEach((publicKey, index) =>
	{
		publicKeyAndDataMap[publicKey] = {
			latestPriceMessage: priceMessages[Number(index)][0] || null,
			recoveryProgress: recoveryProgress[Number(index)],
		};
	});

	return publicKeyAndDataMap;
};

export const orderOracleSummariesForDisplaySequence = function(oracleSummaries: Array<IOracleSummary>): Array<IOracleSummary>
{
	// order the summaries in a sequence that is defined in the constant
	const orderedOracleSummaries: Array<IOracleSummary> = [];

	// Sort oracles based on metadata.
	OracleConstants.ORACLE_ASSET_DISPLAY_SEQUENCE.forEach((oracleSourceUnitCode: string) =>
	{
		oracleSummaries.forEach((oracleSummary) =>
		{
			if(oracleSummary.sourceNumeratorUnitCode === oracleSourceUnitCode)
			{
				orderedOracleSummaries.push(oracleSummary);
			}
		});
	});

	// Show all oracles with missing metadata at the bottom.
	oracleSummaries.forEach((oracleSummary) =>
	{
		if(typeof oracleSummary.sourceNumeratorUnitCode === 'undefined')
		{
			orderedOracleSummaries.push(oracleSummary);
		}
	});

	return orderedOracleSummaries;
};

/**
 * transform oracle metadata in format used to visualize
 *
 * @param   {Array<IOracleMetadataResponse>}    oracleMetadata      array of oracle metadata fetched from the backend that need to be transformed
 *
 * @returns {Promise<Array<IOracleMetadata>>}   an array of oracle metadata to be used for presentation on the UI
 */
export const preparePublicKeyAndMetadataMap = async function(oracleMetadataMessages: Array<IHexSignedOracleMessage>): Promise<Record<string, IOracleMetadata>>
{
	const publicKeyAndMetadataMap: Record<string, IOracleMetadata> = {};

	for(const metadataMessage of oracleMetadataMessages)
	{
		// extract the message and public key
		const { message, publicKey } = metadataMessage;

		// parse the message
		const parsedMessage = await OracleData.parseMetadataMessage(hexToBin(message));

		const { METADATA_TYPES } = OracleProtocol;

		// extract the metadata type of the message from parsed message
		// NOTE: there is a type assertion here which asserts the metadataType to be one of the keys of OracleProtocol.METADATA_TYPES
		//  as metadataType in metadata message would be one of the keys present in OracleProtocol.METADATA_TYPES
		//  as the oracle generators generate the messages using the same variable of price-oracle library
		const metadataType = String(parsedMessage.metadataType) as IOracleProtocolMetadataTypeKeys;

		// extract the metadata value from the key value object
		const metadataPropertyValueMap: IOracleMetadata = publicKeyAndMetadataMap[publicKey];

		// if the metadata type found in the parsed message is not found in the library constants
		if(typeof METADATA_TYPES[metadataType] === 'undefined')
		{
			// ignore such metadata type and do not try to process any further
			continue;
		}

		// extract the metadata property name based on the metadata type value
		// NOTE: the type assertion here exists because the keys of IOracleMetadata are created
		//  based on the name property of the OracleProtocol.METADATA_TYPES values
		const metadataProperty = OracleProtocol.METADATA_TYPES[metadataType].name as IOracleMetadataKeys;

		// if the public key and metadata map doesn't contain the metadata for the public key accessed on previous line
		if(!metadataPropertyValueMap)
		{
			// initialize the oracle metadata property and value map with blank string properties and values to avoid breaking the UI
			//  All of these properties would always be populated if the generators are working as expected
			publicKeyAndMetadataMap[publicKey] = { ATTESTATION_SCALING: '1' };
		}

		publicKeyAndMetadataMap[publicKey][metadataProperty] = parsedMessage.metadataContent;
	}

	return publicKeyAndMetadataMap;
};

export const getAllOraclePublicKeysAndMetadataMaps = async function(): Promise<Record<string, IOracleMetadata>>
{
	// get the oracle metadata messages from the backend
	const oraclesMetadataMessages = await getOracleMetadata();

	// prepare the public key and metadata map by parsing and processing the metadata messages
	const publicKeyAndMetadataMap = await preparePublicKeyAndMetadataMap(oraclesMetadataMessages);

	return publicKeyAndMetadataMap;
};

/**
 * Prepares the summary of latest price message for an oralce from it's message metrics
 *
 * @param   {IOracleWithMessageMetrics}     oracle                  object containing oracle with message metrics
 * @param   {number}                        attestationScaling      the value by which the oracle price is divided with for presentation purpose
 *
 * @returns
 */
export const getLatestPriceMessageSummaryAttributes = async function(oracle: IOracleWithMessageMetrics, attestationScaling: number): Promise<any>
{
	const latestPrice = String(oracle.messageMetrics.currentPrice / attestationScaling);
	const latestMessageTime = moment.unix(oracle.messageMetrics.maxMessageTimestamp)
		.utc()
		.format('YYYY-MM-DD, HH:mm:ss');
	const messageDetailsUrl = `/oracles/${oracle.publicKey}/${oracle.messageMetrics.maxMessageSequence}`;

	return { latestPrice, latestMessageTime, messageDetailsUrl };
};

/**
 * Returns the price change related presentational info like percentage change, icons and color in which % change needs to be shown
 */
export const getOraclePriceChangePresentation = function(percentageChange: number): IPriceChangePresentation
{
	// set the default value of price change presentation
	let priceChangePercentagePresentation = {
		percentageChange: '0',
		percentageChangeTextColor: 'red',
		priceChangeSymbol: '',
	};

	if(percentageChange)
	{
		priceChangePercentagePresentation =
			{
				percentageChange: Math.abs(percentageChange).toFixed(1),
				percentageChangeTextColor: getPercentageChangeColor(percentageChange),
				priceChangeSymbol: Number(percentageChange) > 0 ? '▴' : '▾',
			};
	}

	return priceChangePercentagePresentation;
};

/**
 * Prepares an object containing recovery progress data from an object containing oracle with message metrics
 *
 * @param       {IOracleWithMessageMetrics}     oracle      object containing oracle with message metrics
 *
 * @returns     {IRecoveryProgress}             object containing recovery progress data
 */
export const prepareRecoveryProgressFromOraclesData = function(oracle: IOracleWithMessageMetrics): IRecoveryProgress
{
	const messagesCount = oracle.messageMetrics.availableMessageCount;
	const { maxMessageSequence } = oracle.messageMetrics;
	const recoveryProgressPercent = Number(Number((messagesCount / maxMessageSequence) * 100).toFixed(2));

	const recoveryProgress: IRecoveryProgress =
	{
		messagesCount,
		maxMessageSequence,
		maxMessageTimestamp: oracle.messageMetrics.maxMessageTimestamp,
		recoveryProgressPercent,
	};

	return recoveryProgress;
};

/**
 * Prepares an object containing keys as public key and values include all of the data to prepare oracle summaries
 *
 * @returns {Promise<Record<string, IPublicKeyAndOracleDataMap>>}   object containing recovery progress data
 */
export const preparePublicKeyAndOraclesDataMap = async function(): Promise<Record<string, IPublicKeyAndOracleDataMap>>
{
	// get the oracles with recovery information and latest price
	const oracles = await getOracles();
	const publicKeyAndOraclesData: Record<string, any> = {};

	oracles.forEach((oracle) =>
	{
		// Ignore this oracle if it has no message metrics.
		if(typeof oracle.messageMetrics === 'undefined')
		{
			return;
		}

		// find the difference between prices
		const priceDifference = oracle.messageMetrics.currentPrice - oracle.messageMetrics.oldPrice;

		// calculate the difference percentage between old and new price points
		const percentageDifference = ((priceDifference / oracle.messageMetrics.oldPrice) * 100);

		publicKeyAndOraclesData[oracle.publicKey] = {
			recoveryProgress: prepareRecoveryProgressFromOraclesData(oracle),
			currentPrice: oracle.messageMetrics.currentPrice,
			percentageDifference,
			oracleWithMessageMetrics: oracle,
		};
	});

	return publicKeyAndOraclesData;
};

export const prepareOracleSummaries = async function(
	publicKeyAndMetadataMap: Record<string, IOracleMetadata>,
): Promise<Array<IOracleSummary>>
{
	const oracleSummaries: Array<IOracleSummary> = [];

	const publicKeyAndOraclesData = await preparePublicKeyAndOraclesDataMap();

	// loop through all publicKey's oracle summary data
	// NOTE: This assumes that metadata exist where oracle data exist, and will fail if that is not the case.
	for(const publicKey of Object.keys(publicKeyAndOraclesData))
	{
		// access the metadata values corresponding to the messages fetched from the backend
		// (sequence of metadata values and messages are same with respect to the public key)
		const {
			SOURCE_NAME,
			SOURCE_NUMERATOR_UNIT_NAME,
			SOURCE_NUMERATOR_UNIT_CODE,
			SOURCE_DENOMINATOR_UNIT_NAME,
			SOURCE_DENOMINATOR_UNIT_CODE,
			ATTESTATION_SCALING,
			OPERATOR_NAME,
			OPERATOR_WEBSITE,
			STARTING_TIMESTAMP,
			ENDING_TIMESTAMP,
		} = publicKeyAndMetadataMap[publicKey];

		let recoveryProgressPresentation = null;

		// get the appropriate icon and tooltip text based on the recovery progress attributes
		recoveryProgressPresentation = getRecoveryProgressPresentationAttributes(publicKeyAndOraclesData[publicKey].recoveryProgress);

		const latestPriceMessageSummaryAttributes = await getLatestPriceMessageSummaryAttributes(publicKeyAndOraclesData[publicKey].oracleWithMessageMetrics, Number(ATTESTATION_SCALING));

		const percentageChangeAttributes = getOraclePriceChangePresentation(publicKeyAndOraclesData[publicKey].percentageDifference);

		const { minMessageTimestamp, maxMessageTimestamp } = getPriceGraphPointsQueryParams(OracleConstants.TIME_RANGES.DAY);

		oracleSummaries.push(
			{
				oraclePublicKey: publicKey,
				sourceName: SOURCE_NAME,
				sourceNumeratorUnitName: SOURCE_NUMERATOR_UNIT_NAME,
				sourceNumeratorUnitCode: SOURCE_NUMERATOR_UNIT_CODE,
				sourceDenominatorUnitName: SOURCE_DENOMINATOR_UNIT_NAME,
				sourceDenominatorUnitCode: SOURCE_DENOMINATOR_UNIT_CODE,
				svgGraphUrl: `${process.env.REACT_APP_RELAY_BASE_URL}/art/v0/priceGraphImage?publicKey=${publicKey}&minMessageTimestamp=${minMessageTimestamp}&maxMessageTimestamp=${maxMessageTimestamp}&timePeriodGranularity=minute`,
				recoveryProgressPresentation,
				operatorName: OPERATOR_NAME,
				operatorWebsite: OPERATOR_WEBSITE,
				...latestPriceMessageSummaryAttributes,
				priceChangePresentation: percentageChangeAttributes,
				assetIcon: OracleConstants.ORACLE_ASSET_ICONS_MAP[SOURCE_NUMERATOR_UNIT_CODE as IOracleSourceUnitCode],
				startingTimestamp: Number(STARTING_TIMESTAMP),
				endingTimestamp: Number(ENDING_TIMESTAMP),
			},
		);
	}

	// order the summaries in the sequence that has to be displayed on the UI
	const orderedOracleSummaries = orderOracleSummariesForDisplaySequence(oracleSummaries);

	return orderedOracleSummaries;
};

export const extractMessagesCountFromSummaries = function(summaries: IOracleSummary[]): number
{
	let messagesCountSum = 0;
	summaries.forEach((summary) =>
	{
		messagesCountSum += summary.messagesCount;
	});

	return messagesCountSum;
};

/**
 * Prepares the public key and change percentage map from oracles data map
 *
 * @param   {Record<string, IPublicKeyAndOracleDataMap>}    publicKeyAndOraclesDataMap    A map of public key and oracle data fields containing percentageDifference as one of the fields
 *
 * @returns {Record<string, number>}
 */
export const preparePublicKeyAndChangePercentageMap = function(publicKeyAndOraclesDataMap: Record<string, IPublicKeyAndOracleDataMap>): Record<string, number>
{
	// initialize variable to store the publicKey and change percentage map
	const publicKeyAndChangePercentageMap: Record<string, number> = {};

	// loop through the public key and oracle data map to extract the percentage difference and store it in publicKeyAndChangePercentageMap
	Object.keys(publicKeyAndOraclesDataMap).forEach((publicKey: string) =>
	{
		publicKeyAndChangePercentageMap[publicKey] = publicKeyAndOraclesDataMap[publicKey].percentageDifference;
	});

	return publicKeyAndChangePercentageMap;
};
