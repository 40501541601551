import { ReactElement, useState } from 'react';
import css from '../css/MessagesTable.module.css';
import { Link } from 'react-router-dom';

interface ITableCellProps
{
	primaryIcon?: string;
	content: string;
	isTruncatedContent?: boolean;
	linkTo?: string;
	primaryIconColor?: string;
	hideOnSmallScreen?: boolean;
	tooltipText?: string;
}

const TableCell = function({ primaryIcon, content, isTruncatedContent, linkTo, primaryIconColor, hideOnSmallScreen, tooltipText }: ITableCellProps): ReactElement<any>
{
	return <td
		style={isTruncatedContent ? { position: 'relative' } : {}}
		className={`${hideOnSmallScreen ? css.hideOnSmallScreen : css.tableCell} elementWithTooltip`}
	>
		{primaryIcon && <span style={{ color: primaryIconColor }}>{primaryIcon}</span>}
		<span className={isTruncatedContent ? css.truncateCell : ''}>
			{linkTo ? <Link to={linkTo}>{content}</Link> : content}
		</span>
		{tooltipText && <span className='toolTip'>{tooltipText}</span>}
	</td>;
};

export default TableCell;
