// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OracleMetadataContainer_metadataWidgetContainer__qlI0-\n{\n\tmargin-top: var(--space-huge);\n\tmargin-bottom: var(--space-huge);\n}\n\n.OracleMetadataContainer_metadataHeading__6bttM\n{\n\tdisplay: none;\n\tmargin: var(--space-normal) 0 var(--space-normal) 0;\n}\n\n.OracleMetadataContainer_oracleMetadataList__xX-Dt\n{\n\tdisplay: flex;\n\tflex-direction: row;\n\tflex-wrap: wrap;\n\tgap: var(--list-item-gap);\n}\n\n.OracleMetadataContainer_messageDetailsSubHeading__ZVwpj\n{\n\tdisplay: none;\n\tmargin: var(--space-normal) 0 var(--space-normal) 0;\n}\n\n.OracleMetadataContainer_recoveryIcon__vZ3N\\+\n{\n\theight: var(--space-thick);\n\tvertical-align: text-top;\n}\n", "",{"version":3,"sources":["webpack://./src/css/OracleMetadataContainer.module.css"],"names":[],"mappings":"AAAA;;CAEC,6BAA6B;CAC7B,gCAAgC;AACjC;;AAEA;;CAEC,aAAa;CACb,mDAAmD;AACpD;;AAEA;;CAEC,aAAa;CACb,mBAAmB;CACnB,eAAe;CACf,yBAAyB;AAC1B;;AAEA;;CAEC,aAAa;CACb,mDAAmD;AACpD;;AAEA;;CAEC,0BAA0B;CAC1B,wBAAwB;AACzB","sourcesContent":[".metadataWidgetContainer\n{\n\tmargin-top: var(--space-huge);\n\tmargin-bottom: var(--space-huge);\n}\n\n.metadataHeading\n{\n\tdisplay: none;\n\tmargin: var(--space-normal) 0 var(--space-normal) 0;\n}\n\n.oracleMetadataList\n{\n\tdisplay: flex;\n\tflex-direction: row;\n\tflex-wrap: wrap;\n\tgap: var(--list-item-gap);\n}\n\n.messageDetailsSubHeading\n{\n\tdisplay: none;\n\tmargin: var(--space-normal) 0 var(--space-normal) 0;\n}\n\n.recoveryIcon\n{\n\theight: var(--space-thick);\n\tvertical-align: text-top;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"metadataWidgetContainer": "OracleMetadataContainer_metadataWidgetContainer__qlI0-",
	"metadataHeading": "OracleMetadataContainer_metadataHeading__6bttM",
	"oracleMetadataList": "OracleMetadataContainer_oracleMetadataList__xX-Dt",
	"messageDetailsSubHeading": "OracleMetadataContainer_messageDetailsSubHeading__ZVwpj",
	"recoveryIcon": "OracleMetadataContainer_recoveryIcon__vZ3N+"
};
export default ___CSS_LOADER_EXPORT___;
