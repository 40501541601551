// Import library for parsing hex strings.
import { hexToBin } from '@bitauth/libauth';

/**
 * Oracle Protocol Constants
 * @namespace
 */
export const OracleProtocol =
{
	/** Oracle protocol identified to use in OP_RETURN outputs. */
	IDENTIFIER: hexToBin('71808079'),

	/** Network Port to use for requests. */
	PORT_REQUEST: 7083,

	/** Network Port to use for broadcasts. */
	PORT_BROADCAST: 7084,

	/** Network Port to use for relays. */
	PORT_RELAY: 7085,

	/** Network Host for all interfaces (*). */
	ADDRESS_ANY: '*',

	/** Network Host to use for local interface (127.0.0.1). */
	ADDRESS_LOCAL: '127.0.0.1',

	/** The minimum message length in bytes of an oracle message, assuming the content of the message is exactly 1 byte large. */
	MINIMUM_MESSAGE_LENGTH: 12,

	// the number of milliseconds to wait while receiving a message using Request.receive function
	// value of -1 results in waiting asynchronously until a message is available
	DEFAULT_RECEIVE_TIMEOUT: -1,

	// Map of metadata name and types.
	// The types (or values in the below hash) correspond to the dataSequenceOrType attribute of the oracle message
	//  or metadataType attribute of a parsed metadata message
	METADATA_TYPES:
	{
		'-1': { name: 'OPERATOR_NAME', description: 'Human readable name of the operator of the oracle', format: 'string' },
		'-2': { name: 'OPERATOR_WEBSITE', description: 'Link to more information on the operator of the oracle', format: 'string' },
		'-3': { name: 'RELAY_SERVER', description: 'Link to relay server where this oracle\'s messages can be acquired', format: 'string' },
		'-4': { name: 'STARTING_TIMESTAMP', description: 'Unix timestamp at which users can expect the oracle to start reliably producing price messages', format: 'Number' },
		'-5': { name: 'ENDING_TIMESTAMP', description: 'Unix timestamp at which users can expect the oracle to stop reliably producing price messages', format: 'Number' },
		'-6': { name: 'ATTESTATION_SCALING', description: 'Scaling value that the source price was multiplied by in order to retain precision when stored as an integer in oracle messages', format: 'Number' },
		'-7': { name: 'ATTESTATION_PERIOD', description: 'How often the oracle creates a price message in milliseconds', format: 'Number' },
		'-8': { name: 'OPERATOR_HASH', description: 'Sha256 hash of the policy referenced by OPERATOR_WEBSITE', format: 'string' },
		'-51': { name: 'SOURCE_NAME', description: 'Human readable name of the data source the oracle observes', format: 'string' },
		'-52': { name: 'SOURCE_WEBSITE', description: 'Link to more information on the data source the oracle', format: 'string' },
		'-53': { name: 'SOURCE_NUMERATOR_UNIT_NAME', description: 'Human readable name of the original source data numerator', format: 'string' },
		'-54': { name: 'SOURCE_NUMERATOR_UNIT_CODE', description: 'Short code of the original source data numerator (Commonly ISO-4217 currency codes, but not required)', format: 'string' },
		'-55': { name: 'SOURCE_HASH', description: 'Sha256 hash of the policy referenced by SOURCE_WEBSITE', format: 'string' },
		'-56': { name: 'SOURCE_DENOMINATOR_UNIT_NAME', description: 'Human readable name of the original source data denominator', format: 'string' },
		'-57': { name: 'SOURCE_DENOMINATOR_UNIT_CODE', description: 'Short code of the original source data denominator (Commonly ISO-4217 currency codes, but not required)', format: 'string' },
	},

};
