// import utils
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import css from '../css/OracleSummaries.module.css';
import moment from 'moment';

// import types
import type IOracleSummary from '../interfaces/IOracleSummary';

interface ISummaryProps
{
	summary: IOracleSummary[];
}

const currentUnixTimestamp = Math.round(Date.now() / 1000);

//
const isBetaOracle = function(summary: IOracleSummary): boolean
{
	// Set up a list of our staging oracle keys.
	const stagingOraclePublicKeys =
	[
		'02d3c1de9d4bc77d6c3608cbe44d10138c7488e592dc2b1e10a6cf0e92c2ecb047',
		'03c5e3e6a2fe9ed9be3c71a11e7808cf8428bc9ca48808d05a6fa2526865964f06',
		'03994dc2c759375e98afbf5049383cd987001c346d0f11aa262c105874fb1390c3',
		'03e980928f14fc98e1f9d75d15f0b67dc58cdd3f5c641b8f825b146bcc04bd232c',
		'03e9342b4d07dc35db0f555b80e19645b2a2a95a22675b50ead248d551a900fdec',
		'03c22127c967bb28ec518fcc225164100df40470a1f6b457cd3a85adb051dcaa56',
		'03518d199a4ca5dc06ecb1068416acde321df1b8d6f09149744b1e0fb38c92c92c',
		'0330779426934d4fe5d18a3721e9eae246150501ebc537e866d2841369daeb0691',
	];

	// return true if the oracle is one of the beta oracles.
	return stagingOraclePublicKeys.includes(summary.oraclePublicKey);
};

//
const isBetaOracleImportant = function(summary: IOracleSummary): boolean
{
	// Set up a list of our staging oracle keys.
	const importantStagingOraclePublicKeys =
	[
		'02d3c1de9d4bc77d6c3608cbe44d10138c7488e592dc2b1e10a6cf0e92c2ecb047',
		'03e980928f14fc98e1f9d75d15f0b67dc58cdd3f5c641b8f825b146bcc04bd232c',
	];

	// return true if the oracle is one of the beta oracles.
	return importantStagingOraclePublicKeys.includes(summary.oraclePublicKey);
};

// Set up utility function to determine if an oracle is currently active and claiming to be reliable
const isActive = function(summary: IOracleSummary): boolean
{
	// Return false to indicate that all of our staging oracles are retired.
	if(isBetaOracle(summary))
	{
		return false;
	}

	const alreadyStarted = (summary.startingTimestamp ? summary.startingTimestamp < currentUnixTimestamp : false);
	const alreadyEnded   = (summary.endingTimestamp ? summary.endingTimestamp < currentUnixTimestamp : false);

	return (alreadyStarted && !alreadyEnded);
};

const isUpcoming = function(summary: IOracleSummary): boolean
{
	const alreadyStarted = (summary.startingTimestamp ? summary.startingTimestamp < currentUnixTimestamp : false);
	const alreadyEnded   = (summary.endingTimestamp ? summary.endingTimestamp < currentUnixTimestamp : false);

	return (!alreadyStarted && !alreadyEnded);
};

const isRetired = function(summary: IOracleSummary): boolean
{
	const alreadyStarted = (summary.startingTimestamp ? summary.startingTimestamp < currentUnixTimestamp : false);
	const alreadyEnded   = (summary.endingTimestamp ? summary.endingTimestamp < currentUnixTimestamp : false);

	return (alreadyStarted && alreadyEnded);
};

const isUnknown = function(summary: IOracleSummary): boolean
{
	return !summary.sourceNumeratorUnitCode;
};

const formatDate = function(timestamp: number): string
{
	return moment.unix(Number(timestamp))
		.utc()
		.format('YYYY-MM-DD, HH:mm:ss');
};

/**
 * Component to render oracle summaries
 *
 * @param {ISummaryProps} props - input props for the component
 *
 * @returns {ReactElement<ISummaryProps>} react component
 */
const OracleSummaries = (props: ISummaryProps): ReactElement<ISummaryProps> => (
	<section className={css.oracleList} data-testid='summaryCardsContainer'>

		{
			(props.summary.filter((summary) => isActive(summary)).length > 0) && (
			<>
				<h2>Available Oracles</h2>
				<table>
					<thead className={css.summaryTableHeadings}>
						<tr>
							{/* this column contains recovery progress icon and doesn't need content in header */}
							<th></th>
							<th>Oracle</th>
							<th className='hideOnSmallScreen'>Timestamp</th>
							<th className='hideOnSmallScreen'>Operator</th>
							<th className={css.headingPrice}>Price</th>
							<th>Last&nbsp;24H</th>
						</tr>
					</thead>
					<tbody>
						{
							props.summary.filter((summary) => isActive(summary)).map((summary, index) => (
								<tr key={index}>
									<td className={`${css.recoveryProgress} elementWithTooltip`}>
										{summary.recoveryProgressPresentation && <span
											style={{ color: summary.recoveryProgressPresentation.iconColor }}
											className={summary.recoveryProgressPresentation.icon}
										/>}
										{summary.recoveryProgressPresentation
										&& <span className='toolTip'>{summary.recoveryProgressPresentation.title}</span>}
									</td>
									<td>
										<Link to={`/oracles/${summary.oraclePublicKey}`} className={css.linkToDetailsPage}>
											<img src={summary.assetIcon} className={css.assetIcon} />
											<div className={css.oracleIdentity}>
												<strong>{summary.sourceNumeratorUnitCode} / {summary.sourceDenominatorUnitCode}</strong>
												<br />
												<small className={css.oraclePublicKey}>{summary.oraclePublicKey}</small>
											</div>
										</Link>
									</td>
									<td className={`${css.summaryLatestMessageTime} hideOnSmallScreen`}>
										<Link to={summary.messageDetailsUrl || ''}>
											<strong>{summary.latestMessageTime}</strong>
										</Link>
									</td>
									<td className='hideOnSmallScreen'>
										<a href={summary.operatorWebsite || ''} target='_blank'>
											{summary.operatorName}
										</a>
									</td>

									<td className={css.summaryLatestPrice}>
										{summary.latestPrice}
									</td>
									<td className={css.graphAndChangePercentColumn}>
										<img className={css.graph} src={summary.svgGraphUrl} data-testid='svgGraphUrl'/>
										{summary.priceChangePresentation && <small
											className={css.percentageChange}
											style={{ color: summary.priceChangePresentation.percentageChangeTextColor }}
										>
											{summary.priceChangePresentation.priceChangeSymbol}&nbsp;{summary.priceChangePresentation.percentageChange}%
										</small>}
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</>
			)
		}

		{
			(props.summary.filter((summary) => isBetaOracle(summary)).length > 0) && (
			<>
				<h2>Beta Oracles</h2>
				<table>
					<thead className={css.summaryTableHeadings}>
						<tr>
							{/* this column contains recovery progress icon and doesn't need content in header */}
							<th></th>
							<th>Beta Oracle</th>
							<th className='hideOnSmallScreen'>Timestamp</th>
							<th className='hideOnSmallScreen'>Operator</th>
							<th className={css.headingPrice}>Price</th>
							<th>Last&nbsp;24H</th>
						</tr>
					</thead>
					<tbody>
						{
							props.summary.filter((summary) => isBetaOracleImportant(summary)).map((summary, index) => (
								<tr key={index}>
									<td className={`${css.recoveryProgress} elementWithTooltip`}>
										{summary.recoveryProgressPresentation && <span
											style={{ color: summary.recoveryProgressPresentation.iconColor }}
											className={summary.recoveryProgressPresentation.icon}
										/>}
										{summary.recoveryProgressPresentation
										&& <span className='toolTip'>{summary.recoveryProgressPresentation.title}</span>}
									</td>
									<td>
										<Link to={`/oracles/${summary.oraclePublicKey}`} className={css.linkToDetailsPage}>
											<img src={summary.assetIcon} className={css.assetIcon} />
											<div className={css.oracleIdentity}>
												<strong>{summary.sourceNumeratorUnitCode} / {summary.sourceDenominatorUnitCode}</strong>
												<br />
												<small className={css.oraclePublicKey}>{summary.oraclePublicKey}</small>
											</div>
										</Link>
									</td>
									<td className={`${css.summaryLatestMessageTime} hideOnSmallScreen`}>
										<Link to={summary.messageDetailsUrl || ''}>
											<strong>{summary.latestMessageTime}</strong>
										</Link>
									</td>
									<td className='hideOnSmallScreen'>
										<a href={summary.operatorWebsite || ''} target='_blank'>
											{summary.operatorName}
										</a>
									</td>

									<td className={css.summaryLatestPrice}>
										{summary.latestPrice}
									</td>
									<td className={css.graphAndChangePercentColumn}>
										<img className={css.graph} src={summary.svgGraphUrl} data-testid='svgGraphUrl'/>
										{summary.priceChangePresentation && <small
											className={css.percentageChange}
											style={{ color: summary.priceChangePresentation.percentageChangeTextColor }}
										>
											{summary.priceChangePresentation.priceChangeSymbol}&nbsp;{summary.priceChangePresentation.percentageChange}%
										</small>}
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</>
			)
		}
	</section>

);

export default OracleSummaries;
