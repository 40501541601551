import { ReactElement, useContext, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { globalStore } from './state/StateProvider';
import { getAllOraclePublicKeysAndMetadataMaps } from './utils/format';
import { getOraclePublicKeyAndMetadataMapSuccessAction } from './state/actions';

// import components to render
import OracleDetailsPage from './pages/OracleDetailsPage';
import OracleMessageDetailsPage from './pages/OracleMessageDetailsPage';
import AboutPage from './pages/AboutPage';
import Footer from './components/Footer';

// import types
import IndexPage from './pages/IndexPage';
import MaintenancePage from './pages/MaintenancePage';

// import css
import './css/GlobalStyles.css';

// Import GP and oracle logo.
import oraclesLogo from './assets/logo-light.png';
import FaqPage from './pages/FaqPage';

const App = function(): ReactElement
{
	const { state, dispatch } = useContext(globalStore);

	// callback executed when the component mounts
	useEffect(() =>
	{
		// get the prepared oracle public key and metadata map
		getAllOraclePublicKeysAndMetadataMaps().then((publicKeyAndMetadataMap) =>
		{
			// store the prepared oracle public key and metadata map in the global context
			dispatch(getOraclePublicKeyAndMetadataMapSuccessAction(publicKeyAndMetadataMap));
		});
	}, []);

	return (
		<div>
			<header>
				<nav>
					<a href='/'>
						<img className='navLogo' src={oraclesLogo} />
						<div>
							<h1>Oracles.Cash</h1>
							<p>BEAUTIFUL, ACCOUNTABLE, VERIFIABLE PRICE ORACLES</p>
						</div>
					</a>
				</nav>
			</header>
			{
				<Router basename={process.env.PUBLIC_URL}>
					<Routes>
						<Route path='/' element={<IndexPage/>} />
						<Route path='/oracles/:oraclePublicKey' element={<OracleDetailsPage/>} />
						<Route path='/oracles/:oraclePublicKey/:messageSequence' element={<OracleMessageDetailsPage/>} />
						<Route path='/about' element={<AboutPage/>} />
						<Route path='/faq' element={<FaqPage/>} />
					</Routes>
				</Router>
			}
			<Footer />
		</div>
	);
};

// TODO: restore this into the footer
// {relayMetrics.length > 0 && <Metrics metrics={relayMetrics} heading={'Relay Metrics'} backgroundColor={'#e6f0e9'} />}

export default App;
