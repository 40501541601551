// import types
import type IPreparedPriceGraphDataPoint from '../interfaces/IPreparedPriceGraphDataPoint';
import type ISelectOptions from '../interfaces/ISelectOptions';
import type ITimeRange from '../interfaces/ITimeRange';
import type { IOracleSourceUnitCode } from '../interfaces/derivedTypes';

// import utils and dependencies
import { ReactElement, ChangeEvent, useEffect, useState, useContext } from 'react';
import { Area, Line, ComposedChart, Tooltip, XAxis, YAxis } from 'recharts';
import OracleConstants from '../constants/OracleConstants';
import { globalStore } from '../state/StateProvider';
import { getFormattedPriceGraphData, prepareYAxisLabels } from '../utils/graphUtils';

// import css
import css from '../css/PriceGraph.module.css';

// import component to display the custom y axis label on the graph
import GraphCustomizedAxisTick from './GraphCustomizedAxisTick';

interface IProps
{
	oraclePublicKey: string;
	currentPrice: string | null;
}

/**
 * Component to render the price graph data on the oracle details page
 *
 * @param {IProps} props - input props for the component
 *
 * @returns {ReactElement}
 */
const PriceGraph = (props: IProps): ReactElement<IProps> =>
{
	// state variable and function to update the state variable to render the price graph
	const [ priceGraphData, setPriceGraphData ] = useState<Array<IPreparedPriceGraphDataPoint>>([]);

	// state variable and function to update the state variable to render the y axis labels on the price graph
	const [ priceGraphYAxisLabels, setPriceGraphYAxisLabels ] = useState<Array<string>>([]);

	// state variable and function to update the state variable to render the price graph based on the time period granularity
	const [ timePeriodGranularity, setTimePeriodGranularity ] = useState<ITimeRange>(OracleConstants.TIME_RANGES.DAY);

	const timeRangeOptions: Array<ISelectOptions> = OracleConstants.TIME_RANGE_OPTIONS;

	// get the app wide global state
	const { state } = useContext(globalStore);

	// extract the metadata from the oracle public key and metadata map
	const metadata = state.oraclePublicKeyAndMetadataMap[props.oraclePublicKey];

	// state variable to track the screen width
	const [ graphWidth, setGraphWidth ] = useState(1024);

	// callback triggered when the window resizes
	const onResize = (): void =>
	{
		// we have a type assertion below because we always know that the section exists
		const section = document.getElementById('priceGraphSection') as HTMLElement;

		// set graph to the width of the section that contains the graph
		const sectionWidth = section.clientWidth;
		setGraphWidth(sectionWidth);
	};

	// Callback triggered when the component unmounts.
	const onComponentWillUnMount = function(): void
	{
		window.removeEventListener('resize', onResize);
	};

	/**
	 * Sets up the component state with newly selected graph data to be shown
	 *
	 * @param {string}  publicKey                public key of the selected oracle
	 * @param {number}  attestationScaling       the value by which the oracle price is divided with for presentation purpose
	 */
	const populatePriceGraph = async function(publicKey: string, attestationScaling: number): Promise<void>
	{
		// get the graph data in format that is used to visualize data in UI
		const priceGraphWithPriceRoundedOff = await getFormattedPriceGraphData(publicKey, timePeriodGranularity, attestationScaling);

		// set the price graph data in the state variable to render it in the graph
		setPriceGraphData(priceGraphWithPriceRoundedOff);

		// get the label text to be displayed on the y axis of the graph
		const yAxisLabels = prepareYAxisLabels(priceGraphWithPriceRoundedOff);

		// set the y axis label values in state variable in order to render it in the graph
		setPriceGraphYAxisLabels(yAxisLabels);
	};

	/**
	* Callback triggered when the time period granularity value changes. It sets up the price graph with newly selected time period granularity
	*
	* @param    {ChangeEvent<HTMLSelectElement>}    event   change event triggered on selection of a dropdown option
	*
	* @returns  {void}
	*/
	const onTimePeriodGranularityChange = function(event: ChangeEvent<HTMLSelectElement>): void
	{
		setTimePeriodGranularity(event.target.value as ITimeRange);
	};

	// callback triggered when the component mounts
	useEffect(() =>
	{
		// call the resize function to initialize the graph width
		onResize();

		// set up the event listener on change in screen width
		window.addEventListener('resize', onResize);

		// set up the function to be called when the component unmounts
		return onComponentWillUnMount;
	}, []);

	// callback triggered when any attribute on which graph is dependent changes
	useEffect(() =>
	{
		// if the dependencies to populate the graph are available
		if(Object.keys(state.oraclePublicKeyAndMetadataMap).length > 0 && metadata?.ATTESTATION_SCALING)
		{
			// populate the oracle price graph
			populatePriceGraph(props.oraclePublicKey, Number(metadata.ATTESTATION_SCALING));
		}
	}, [ timePeriodGranularity, Object.keys(state.oraclePublicKeyAndMetadataMap).length, metadata?.ATTESTATION_SCALING ]);

	return (
		<section className={css.priceGraphContainer} id='priceGraphSection'>
			<h2 className={css.graphHeading}>
				{metadata?.SOURCE_NUMERATOR_UNIT_CODE && <>
					<img style={{ maxWidth: '2rem' }} src={OracleConstants.ORACLE_ASSET_ICONS_MAP[metadata.SOURCE_NUMERATOR_UNIT_CODE as IOracleSourceUnitCode]} />
					&nbsp;{metadata.SOURCE_NUMERATOR_UNIT_NAME} ({metadata.SOURCE_NUMERATOR_UNIT_CODE}) / {metadata.SOURCE_DENOMINATOR_UNIT_NAME} ({metadata?.SOURCE_DENOMINATOR_UNIT_CODE})
				</>}
			</h2>
			{props.currentPrice && <h3 className={css.primarySourceUnitPrice}>{props.currentPrice}  {metadata?.SOURCE_NUMERATOR_UNIT_CODE} / {metadata?.SOURCE_DENOMINATOR_UNIT_CODE}</h3>}
			<form>
				<label>Time Range</label>
				<select
					className={css.timePeriodGranularitySelector}
					onChange={ (e) => onTimePeriodGranularityChange(e) }
					value={timePeriodGranularity}
				>
					{timeRangeOptions.map((item, index) => (<option value={item.value} key={index}>{item.text}</option>))}
				</select>
			</form>
			<ComposedChart
				width={graphWidth}
				height={250}
				data={priceGraphData}
				margin={{ top: 5, right: 30, left: 30, bottom: 65 }}
			>
				<XAxis
					dataKey="timePeriod"
					stroke="#fff"
					// props passed to the component inside tick attribute are passed by the recharts library during run time
					// hence we do not pass any props but TS doesn't know that, so use ts-ignore to suppress any type errors
					// @ts-ignore
					tick={<GraphCustomizedAxisTick />}
				/>
				{priceGraphYAxisLabels.length > 0 && <YAxis
					orientation="left"
					dataKey="averagePrice"
					stroke="#fff"
					// specify the start and end of range that needs to be visible on the y axis
					domain={[
						// set the start of visible range on y axis to the 1st element of priceGraphYAxisValues parsed as number
						Number(priceGraphYAxisLabels[0]),
						// set the end of visible range on y axis to the last element of priceGraphYAxisValues parsed as number
						Number(priceGraphYAxisLabels[priceGraphYAxisLabels.length - 1]),
					]}
					ticks={priceGraphYAxisLabels}
				/>}
				<Tooltip isAnimationActive={false}/>
				<Area dataKey="minAndMaxPriceRange" stroke="var(--transparent)" fill="var(--dark-green)" isAnimationActive={false}/>
				<Line type="linear" dataKey="averagePrice" stroke="var(--light-green)" strokeWidth={2} dot={false} isAnimationActive={false}/>
			</ComposedChart>
		</section>
	);
};

export default PriceGraph;
