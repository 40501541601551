// import types
import type IPreparedPriceGraphDataPoint from '../interfaces/IPreparedPriceGraphDataPoint';
import type ITimeRange from '../interfaces/ITimeRange';
import type IGraphYAxisValuesRange from '../interfaces/IGraphYAxisValuesRange';

// import constants
import OracleConstants from '../constants/OracleConstants';

// import utils
import moment from 'moment';
import { getPriceGraphData } from './apis';
import { translateTimeRangeToAggregationPeriod, getPriceGraphPointsQueryParams, getPriceWithDecimalPrecision } from './misc';

/**
 * Returns the formatted values to be shown on the y axis of the graph based on the y axis values and GRAPH_Y_AXIS_LABEL_COUNTS config
 *
 * @param   {Array<number>}     yAxisValues   the rounded off y axis values that are plotted on the graph
 *
 * @returns {Array<string>}     array of values to be shown on the y axis labels
 */
const getEvenlySpacedYAxisLabels = function(maxAndMinRange: IGraphYAxisValuesRange): Array<string>
{
	// extract the max and min values from the range
	const { maximum, minimum } = maxAndMinRange;

	// the evenly spaced values need to appear in the same format as y axis values
	// find the number of digits after the decimal place and use the same number to format the labels
	const yAxisValueAsString = String(minimum);
	const numberOfDigitsAfterDecimal = yAxisValueAsString.split('.')[1]?.length || 0;

	// find the difference between the max and min values on the y axis to calculate the interval
	const yAxisLength = maximum - minimum;

	// get the equal parts in which the y axis needs to be split to calculate the interval
	// (Eg: if we need 4 labels on the y axis, it would be split in 3 equal parts)
	const yAxisLabelGapsCount = OracleConstants.GRAPH_Y_AXIS_LABEL_COUNTS - 1;

	// calculate the interval at which we can show a y axis label by splitting the y axis values in number of values to be shown on the UI
	const interval = yAxisLength / yAxisLabelGapsCount;

	// initialize the y axis label values array with 1st point as the min value
	// as min value is the first point that appears at the bottom on y axis
	const yAxisLabelValues = [ minimum.toFixed(numberOfDigitsAfterDecimal) ];

	for(let yAxisGapIndex = 0; yAxisGapIndex < yAxisLabelGapsCount; yAxisGapIndex += 1)
	{
		// add the interval to the previous value (last value in the y axis labels array)
		const nextYAxisLabelValue = Number(yAxisLabelValues[yAxisGapIndex]) + interval;

		// add the next calculated value to the yAxisLabelValues array
		yAxisLabelValues.push(nextYAxisLabelValue.toFixed(numberOfDigitsAfterDecimal));
	}

	return yAxisLabelValues;
};

/**
 * Calculates the maximum and the minimum values range between which the y axis labels need to be displayed
 *
 * @param   {Array<IPreparedPriceGraphDataPoint>}   graphData       the formatted graph data used to plot points on the graph
 *
 * @returns {IGraphYAxisValuesRange}            object containing minimum and maximum graph values
 */
export const getMaxAndMinOfYAxisValues = function(graphData: Array<IPreparedPriceGraphDataPoint>): IGraphYAxisValuesRange
{
	// initialize the max and min variables to store the max value (max of the maxPrice extracted of the price range)
	// and min value (min of the minPrice extracted from the price range) from graph data
	let maxValue = graphData[0].minAndMaxPriceRange[0];
	let minValue = graphData[0].minAndMaxPriceRange[1];

	// find the max all of the max prices and min from all of the min prices among all of the points to be plotted on the graph
	graphData.forEach((graphDataElement) =>
	{
		// extract the max and min prices for any given point from the range
		// (1st element of range is always the min price and 2nd element is max price)
		const minPriceFromRange = graphDataElement.minAndMaxPriceRange[0];
		const maxPriceFromRange = graphDataElement.minAndMaxPriceRange[1];

		if(minPriceFromRange < minValue)
		{
			minValue = minPriceFromRange;
		}

		if(maxPriceFromRange > maxValue)
		{
			maxValue = maxPriceFromRange;
		}
	});

	// add a 10% buffer to the max and min to add some blank space at top and bottom of the graph
	const totalValues = maxValue - minValue;
	const buffer = (10 / 100) * totalValues;
	minValue -= buffer;
	maxValue += buffer;

	// round off max and min prices to limit the length of the numbers to make them readable on the graph
	// using which further calculations to calculate the evenly spaced y axis values can be done
	// parseFloat function here is used to convert the result of toPrecision to number type preserving the digits after decimal
	// and also avoid scientific notation. Refer to - https://stackoverflow.com/a/4689179/377366 to understand this better
	const roundedOffMaxPrice = parseFloat(maxValue.toPrecision(OracleConstants.GRAPH_Y_AXIS_LABEL_PRECISION));
	const roundedOffMinPrice = parseFloat(minValue.toPrecision(OracleConstants.GRAPH_Y_AXIS_LABEL_PRECISION));

	return { maximum: roundedOffMaxPrice, minimum: roundedOffMinPrice };
};

/**
 * Extracts the average price from the graph data and returns few values that can be shown on the graph on y axis
 *
 * @param       {Array<IPreparedPriceGraphDataPoint>}   graphData    graph data containing x and y axis values
 *
 * @returns     {Array<string>}                     formatted y axis labels
 */
export const prepareYAxisLabels = function(graphData: Array<IPreparedPriceGraphDataPoint>): Array<string>
{
	// get the range of values between which the y axis labels need to be evenly spaced
	const maxAndMinRange = getMaxAndMinOfYAxisValues(graphData);

	// prepare the y axis labels as numeric strings to be shown on the y axis such that
	// the labels are evenly spaced (with equal space between each label)
	const yAxisLabels = getEvenlySpacedYAxisLabels(maxAndMinRange);

	return yAxisLabels;
};

/**
 * Extracts the formatted time stamp from the graph data and returns the values that can be shown on the graph on x axis
 *
 * @param       {ITimeRange}    timePeriodGranularity   time period granularity
 * @param       {number}        averageTimestamp        time value in seconds that needs to be formatted
 *
 * @returns     {string}        formatted time stamp
 */
export const formatGraphXAxisLabel = function(timePeriodGranularity: ITimeRange, averageTimestamp: number): string
{
	// declare variable to store the formatted Timestamp
	let formattedTimestamp;

	const averageTimestampMoment = moment.unix(averageTimestamp).utc();

	// format timestamp based on the time period granularity
	switch(timePeriodGranularity)
	{
		case OracleConstants.TIME_RANGES.DAY:
			formattedTimestamp = averageTimestampMoment.format('YYYY-MMM-DD, HH:mm');
			break;
		case OracleConstants.TIME_RANGES.WEEK:
			formattedTimestamp = averageTimestampMoment.format('YYYY-MMM-DD, HH:mm');
			break;
		case OracleConstants.TIME_RANGES.MONTH:
			formattedTimestamp = averageTimestampMoment.format('YYYY-MMM-DD, HH');
			break;
		case OracleConstants.TIME_RANGES.THREE_MONTHS:
			formattedTimestamp = averageTimestampMoment.format('YYYY-MMM-DD, HH');
			break;
		case OracleConstants.TIME_RANGES.YEAR:
			formattedTimestamp = averageTimestampMoment.format('YYYY-MMM-DD');
			break;
		default:
			throw(Error('Provided time period granularity is invalid'));
	}

	return formattedTimestamp;
};

/*
 * Returns the graph data in format that is used to visualize data in UI
 *
 * @param   {string}                    publicKey                public key of the selected oracle
 * @param   {ITimePeriodGranularity}    timePeriodGranularity    time period granularity for price graph
 * @param   {number}                    attestationScaling       the value by which the oracle price is divided with for presentation purpose
 *
 * @returns {Promise<Array<IFormattedPriceGraphData>>}           array of formatted price graph data that can be used to plot the graph
 */
export const getFormattedPriceGraphData = async function(
	publicKey: string,
	timePeriodGranularity: ITimeRange,
	attestationScaling: number,
): Promise<Array<IPreparedPriceGraphDataPoint>>
{
	// get the time stamp params for the time range for which the price graph needs to be shown
	const { minMessageTimestamp, maxMessageTimestamp } = getPriceGraphPointsQueryParams(timePeriodGranularity);
	const aggregationPeriod = translateTimeRangeToAggregationPeriod(timePeriodGranularity);

	// get price graph data from the backend
	const priceGraph = await getPriceGraphData(publicKey, minMessageTimestamp, maxMessageTimestamp, aggregationPeriod);

	// scale the prices using oracle price factor
	const priceGraphData = priceGraph.map((graphObject) =>
	{
		const scaledPriceWithFactor = getPriceWithDecimalPrecision(graphObject.averagePrice, attestationScaling);
		const scaledMinPriceWithFactor = getPriceWithDecimalPrecision(graphObject.minimumPrice, attestationScaling);
		const scaledMaxPriceWithFactor = getPriceWithDecimalPrecision(graphObject.maximumPrice, attestationScaling);

		const priceGraphDataPoint =
		{
			averagePrice: Number(scaledPriceWithFactor),
			timePeriod: formatGraphXAxisLabel(timePeriodGranularity, graphObject.averageTimestamp),
			minAndMaxPriceRange: [ Number(scaledMinPriceWithFactor), Number(scaledMaxPriceWithFactor) ],
		};

		return priceGraphDataPoint;
	});

	return priceGraphData;
};
