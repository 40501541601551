// import utils
import { ReactElement } from 'react';

// The props are tightly coupled with the recharts library and are passed by recharts
// so the structure and attribute names can not be changed
interface IGraphCustomizedAxisTickProps
{
	// the x co-ordinate of the position where label should appear in the svg graph (computed by recharts at runtime)
	x: number;
	// the y co-ordinate of the position where label should appear in the svg graph (computed by recharts at runtime)
	y: number;
	payload:
	{
		// content of the x axis label
		value: string;
	};
}

/**
 * Customized Axis Tick (axis label value) that will appear on the axis of the graph
 * Documentation to understand the recharts label customization: https://recharts.org/en-US/examples/CustomizedLabelLineChart
 *
 * @param   props                                           props containing the position and content of the y axis label
 *
 * @returns {ReactElement<IGraphCustomizedAxisTickProps>}   Component that presents the label on y axis of graph generated by recharts lib
 */
const GraphCustomizedAxisTick = function(props: IGraphCustomizedAxisTickProps): ReactElement<IGraphCustomizedAxisTickProps>
{
	const { x, y, payload } = props;

	return (
		<g
			// the x and y co-ordinates of the position where label has to appear (computed dynamically by the recharts lib)
			transform={`translate(${x},${y})`}
		>
			<text
				// x axis position of the label with respect to it's default position
				x={0}
				// y axis position of the label with respect to it's default position
				y={0}
				// distance from the x axis line (can only be given in pixels)
				dy={16}
				// the part of the label text that sticks to the x axis point
				// (end makes the ending part of the label stick to the point on y axis)
				textAnchor="end"
				// color of the label (white in our case, can be moved to props if it changes with themes)
				fill="#fff"
				// The angle at which the label text needs to be rotated
				transform="rotate(-20)"
			>
				{payload.value}
			</text>
		</g>
	);
};

export default GraphCustomizedAxisTick;
