/**
 * Encode an integer as a 1-byte Int8LE and store it in a Uint8Array.
 *
 * This method will return an incorrect result for values outside of the range
 * `0x00` to `0xff`.
 *
 * @param {number} value - the number to encode
 */
export const numberToBinInt8LE = function(value: number): Uint8Array
{
	const int16Length = 1;
	const bin = new Uint8Array(int16Length);
	const view = new DataView(bin.buffer, bin.byteOffset, bin.byteLength);
	view.setInt8(0, value);

	return bin;
};

/**
 * Decode a 1-byte Int8LE from a Uint8Array into a number.
 *
 * Throws if `bin` is shorter than 1 bytes.
 *
 * @param {Uint8Array} bin - the Uint8Array to decode
 */
export const binToNumberInt8LE = function(bin: Uint8Array): number
{
	const view = new DataView(bin.buffer, bin.byteOffset, bin.byteLength);

	return view.getInt8(0);
};

/**
 * Returns a suitable push op_code to use to push data into an op_return.
 *
 * @param byteLength {number}   The length of the message to get a push code for.
 *
 * @returns {Uint8Array} the push code(s) to use.
 *
 * @throws {Error} an error if length is longer than 256.
 */
export const getPushDataOpcode = function(byteLength: number): Uint8Array
{
	if(byteLength === 0) return Uint8Array.from([ 0x4c, 0x00 ]);
	if(byteLength < 76) return Uint8Array.from([ byteLength ]);
	if(byteLength < 256) return Uint8Array.from([ 0x4c, byteLength ]);
	throw Error('byteLength is too large.');
};
