// import type
import type IOracleMessageForPresentation from '../interfaces/IParsedOracleMessage';

// import utils
import { ReactElement, useContext } from 'react';
import { globalStore } from '../state/StateProvider';

// import components
import LabelAndValueListItem from './LabelAndValueListItem';

// import style
import css from '../css/OracleMetadataContainer.module.css';

interface IMessageMetadataContainerProps
{
	messageData: IOracleMessageForPresentation;
	selectedOraclePublicKey: string;
}

/**
 * Component to render counter and metrics
 *
 * @param   {IMessageMetadataContainerProps}                  props - input props for the component
 *
 * @returns {ReactElement<IMessageMetadataContainerProps>}    react component
 */
const MessageMetadataContainer = function(props: IMessageMetadataContainerProps): ReactElement<IMessageMetadataContainerProps>
{
	const { messageData, selectedOraclePublicKey } = props;

	// get the app wide global state
	const { state } = useContext(globalStore);

	// get the dependencies required to prepare OracleMetaInfo items
	const { SOURCE_NUMERATOR_UNIT_CODE } = state.oraclePublicKeyAndMetadataMap[props.selectedOraclePublicKey] || {};
	const { SOURCE_DENOMINATOR_UNIT_CODE } = state.oraclePublicKeyAndMetadataMap[props.selectedOraclePublicKey] || {};

	return (
		<>
			<section className={css.metadataWidgetContainer}>
				<h2 className={css.messageDetailsSubHeading}>Parsed message content</h2>
				<ul className={css.oracleMetadataList}>
					<LabelAndValueListItem label='Message Sequence' value={messageData.messageSequence} iconClass='--message-sequence' tooltipText='Sequence number of the message'/>
					<LabelAndValueListItem label='Timestamp' value={messageData.utcMessageTime} iconClass='--clock' tooltipText='Timestamp at which the message is produced'/>
					{ messageData.metadataName ? <>
						<LabelAndValueListItem label='Metadata Type' value={messageData.metadataName || ''} iconClass='--metadata-type' tooltipText='Type of the metadata message'/>
						<LabelAndValueListItem label='Content' value={`${messageData.metadataContent}`} iconClass='--message' tooltipText='Message content'/>
					</> : <>
						<LabelAndValueListItem label='Data Sequence' value={messageData.priceSequence || ''} iconClass='--data-sequence' tooltipText='Sequence number for the data in the oracle message'/>
						<LabelAndValueListItem label='Price' value={`${messageData.scaledPriceByFactor} ${SOURCE_NUMERATOR_UNIT_CODE}/${SOURCE_DENOMINATOR_UNIT_CODE}`} iconClass='--price' tooltipText='Price contained in the message'/>
					</> }
				</ul>
			</section>
			<section className={css.metadataWidgetContainer}>
				<h2 className={css.messageDetailsSubHeading}>Raw message and verification</h2>
				<ul className={css.oracleMetadataList}>
					<LabelAndValueListItem isFullWidth label='Message' value={messageData.message} iconClass='--message' tooltipText='Hex representation of the message'/>
					<LabelAndValueListItem isFullWidth label='Public Key' value={selectedOraclePublicKey} iconClass='--public-key' tooltipText='Hex representation of the public key linked to the message'/>
					<LabelAndValueListItem isFullWidth label='Signature' value={messageData.signature} iconClass='--signature' tooltipText='Hex representation of the message signature'/>
				</ul>
			</section>
		</>
	);
};

export default MessageMetadataContainer;
