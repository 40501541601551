// module to expose misc business logic or calculations

// import types
import type IMinAndMaxTimestamps from '../interfaces/IMinAndMaxTimestamps';
import type ITimeRange from '../interfaces/ITimeRange';

// import constants
import OracleConstants from '../constants/OracleConstants';

// Calculates the seconds in provided days
const daysToSeconds = function(days: number):number
{
	// number of days(provided as argument) * hours in a day * minutes in a hour * seconds in a minute
	// will return how many are seconds are there in provided days
	return days * 24 * 60 * 60;
};

/**
 * Calculates the timestamp range for the graph
 * @param   {ITimeRange}            timeRange       Time period granularity value for selected time period
 *
 * @returns {IMinAndMaxTimestamps}  min and max timestamps between which data needs to be plotted on the graph
 */
export const getPriceGraphPointsQueryParams = function(timeRange: ITimeRange): IMinAndMaxTimestamps
{
	// declare variable to store the min message Timestamp
	let minMessageTimestamp;

	// initialize the max message timestamp to current time below which we would need to get the messages
	const maxMessageTimestamp = Math.round(Date.now() / 1000);

	// variable to get the current time stamp
	const currentTimestamp = Math.round(Math.round(Date.now() / 1000));

	// min message time based on time period granularity
	switch(timeRange)
	{
		case OracleConstants.TIME_RANGES.DAY:
			// set the min message timestamp to 1 days ago
			minMessageTimestamp = currentTimestamp - daysToSeconds(1);
			break;
		case OracleConstants.TIME_RANGES.WEEK:
			// set minMessageTimestamp to 7 days ago
			minMessageTimestamp = currentTimestamp - daysToSeconds(7);
			break;
		case OracleConstants.TIME_RANGES.MONTH:
			// set the min message timestamp to 30 days before today
			minMessageTimestamp = currentTimestamp - daysToSeconds(30);
			break;
		case OracleConstants.TIME_RANGES.THREE_MONTHS:
			// set the min message timestamp to 90 days before today
			minMessageTimestamp = currentTimestamp - daysToSeconds(90);
			break;
		case OracleConstants.TIME_RANGES.YEAR:
			// set the min message timestamp to 365 days before today
			minMessageTimestamp = currentTimestamp - daysToSeconds(365);
			break;
		default:
			throw(Error('Provided time period granularity is invalid'));
	}

	return { minMessageTimestamp, maxMessageTimestamp };
};

// the price value with decimal point precision, based on the price value length
export const getPriceWithDecimalPrecision = function(priceValue: number, oracleFactor: number): string
{
	return (priceValue / oracleFactor).toPrecision(priceValue.toString().length);
};

/**
 * Calculates the aggregation period in seconds from the given timeRange to be used as param to fetch the graph data
 *
 * @param   {ITimePeriodGranularity}    timeRange   Time period granularity value for selected time period
 *
 * @returns {string}                    aggregation period in seconds to get the price area graph data
 */
export const translateTimeRangeToAggregationPeriod = function(timeRange: string): string
{
	// declare variable to store the aggregationPeriod
	let aggregationPeriod;

	// set the aggregation period based on time range
	switch(timeRange)
	{
		case OracleConstants.TIME_RANGES.DAY:
			// set the aggregationPeriod to 5 minutes
			aggregationPeriod = 5 * 60;
			break;
		case OracleConstants.TIME_RANGES.WEEK:
			// set the aggregationPeriod to 10 minutes
			aggregationPeriod =  10 * 60;
			break;
		case OracleConstants.TIME_RANGES.MONTH:
			// set the set the aggregationPeriod to to 1 hour
			aggregationPeriod =  60 * 60;
			break;
		case OracleConstants.TIME_RANGES.THREE_MONTHS:
			// set the aggregationPeriod to 2 hours
			aggregationPeriod =  2 * 60 * 60;
			break;
		case OracleConstants.TIME_RANGES.YEAR:
			// set the aggregationPeriod to one day
			aggregationPeriod =  daysToSeconds(1);
			break;
		default:
			throw(Error('Provided time period granularity is invalid'));
	}

	return aggregationPeriod.toString();
};
