import { ReactElement, useEffect } from 'react';

// import css
import css from '../css/AboutPage.module.css';

/**
* Component to render the about page
*
* @returns {ReactElement}
*/
const AboutPage = function(): ReactElement
{
	// callback triggered when the component mounts
	useEffect(() =>
	{
		// set the document title of the page
		document.title = 'About Oracles.Cash';
	}, []);

	return (
		<main>
			<section className={css.section}>
				<h2>About Cash Oracles</h2>
				<p>Cash Oracles are software that provides signed price information for various assets such that they can be used in the Bitcoin Cash scripting engine.</p>
			</section>
			<section className={css.section}>
				<h2>Operators</h2>
				<p>Each oracle is run by an operator, who can provide a signed metadata message stating who they are and can also provide contact and support information.</p>
			</section>
			<section className={css.section}>
				<h2>Price Sources</h2>
				<p>In order for an oracle to provide price information, they must have a source for their pricing data. Each oracle can use their own source, and can provide signed metadata messages stating what price source they are observing.</p>
			</section>
			<section className={css.section}>
				<h2>Attestation</h2>
				<p>As each oracle observes a price point, they create an attestion in the form of a price oracle message. This message is then signed and distributed on the oracle network.</p>
			</section>
		</main>
	);
};

export default AboutPage;
