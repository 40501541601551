// Import the logging library.
import { debug as debugLog } from 'debug';

// Initialize support for debug message management.
export const debug =
{
	errors:	debugLog('oracle:errors'),
	action:	debugLog('oracle:action'),
	object:	debugLog('oracle:object'),
	network: debugLog('oracle:network'),
	warning: debugLog('oracle:warning'),
};
