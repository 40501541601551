// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* oracle message details container, which will render the oracle message details */\n.OracleMessageDetails_oracleMessageDetailsContainer__lgEA3\n{\n    padding: var(--space-huge);\n}\n\n/* Oracle message details page heading style */\n.OracleMessageDetails_messageDetailsHeading__ELyQp\n{\n    margin-bottom: var(--space-normal);\n}\n", "",{"version":3,"sources":["webpack://./src/css/OracleMessageDetails.module.css"],"names":[],"mappings":"AAAA,mFAAmF;AACnF;;IAEI,0BAA0B;AAC9B;;AAEA,8CAA8C;AAC9C;;IAEI,kCAAkC;AACtC","sourcesContent":["/* oracle message details container, which will render the oracle message details */\n.oracleMessageDetailsContainer\n{\n    padding: var(--space-huge);\n}\n\n/* Oracle message details page heading style */\n.messageDetailsHeading\n{\n    margin-bottom: var(--space-normal);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"oracleMessageDetailsContainer": "OracleMessageDetails_oracleMessageDetailsContainer__lgEA3",
	"messageDetailsHeading": "OracleMessageDetails_messageDetailsHeading__ELyQp"
};
export default ___CSS_LOADER_EXPORT___;
