// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutPage_section__gXN1D\n{\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: var(--space-normal);\n\tmargin: var(--space-huge);\n}\n", "",{"version":3,"sources":["webpack://./src/css/AboutPage.module.css"],"names":[],"mappings":"AAAA;;CAEC,aAAa;CACb,sBAAsB;CACtB,wBAAwB;CACxB,yBAAyB;AAC1B","sourcesContent":[".section\n{\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: var(--space-normal);\n\tmargin: var(--space-huge);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "AboutPage_section__gXN1D"
};
export default ___CSS_LOADER_EXPORT___;
