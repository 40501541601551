// import types
import type ISelectOptions from '../interfaces/ISelectOptions';
import type ITimeRange from '../interfaces/ITimeRange';
import type ITimeRangeKey from '../interfaces/ITimeRangeKey';

// import icons
import ag from '../assets/ag.png';
import au from '../assets/au.png';
import bitcoin from '../assets/bitcoin.png';
import cny from '../assets/cny.png';
import doge from '../assets/doge.png';
import ethereum from '../assets/ethereum.png';
import inr from '../assets/inr.png';
import usd from '../assets/usd.png';
import eur from '../assets/eur.png';
import aud from '../assets/aud.png';

/**
 * Constants specific to the oracles config and other data related to oracles
 */
export default class OracleConstants
{
	// determines the number of oracle messages to be loaded in one network call in the table
	public static readonly ORACLE_MESSAGES_BATCH_COUNT = 10;

	// time period range constants
	public static TIME_RANGES: Record<ITimeRangeKey, ITimeRange> =
		{
			DAY: 'day',
			WEEK: 'week',
			MONTH: 'month',
			THREE_MONTHS: 'threeMonths',
			YEAR: 'year',
		};

	// Options for the time period granularity dropdown
	public static TIME_RANGE_OPTIONS: Array<ISelectOptions> =
		[
			{ value: OracleConstants.TIME_RANGES.DAY, text: 'Last 1 day' },
			{ value: OracleConstants.TIME_RANGES.WEEK, text: 'Last 7 days' },
			{ value: OracleConstants.TIME_RANGES.MONTH, text: 'Last 1 month' },
			{ value: OracleConstants.TIME_RANGES.THREE_MONTHS, text: 'Last 3 months' },
		];

	// oracle asset sequence in which the oracle information of all of the oracles need to be displayed
	public static ORACLE_ASSET_DISPLAY_SEQUENCE = [ 'USD', 'EUR', 'CNY', 'AUD', 'INR', 'XAU', 'XAG', 'BTC', 'ETH', 'DOGE' ];

	// map of oracle asset and icons
	public static ORACLE_ASSET_ICONS_MAP =
		{
			USD: usd,
			EUR: eur,
			CNY: cny,
			AUD: aud,
			INR: inr,
			XAU: au,
			XAG: ag,
			BTC: bitcoin,
			ETH: ethereum,
			DOGE: doge,
		};

	public static DEFAULT_API_RETRY_COUNT = 3;

	public static HTTP_RESPONSE_CODES = {
		TIME_OUT: 408,
	};

	// configure number of labels to appear on the Y axis of the price graph
	public static GRAPH_Y_AXIS_LABEL_COUNTS = 4;

	// configure the precision of the numbers that appear on the y axis on the graph
	public static GRAPH_Y_AXIS_LABEL_PRECISION = 3;
}
