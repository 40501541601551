// import utils
import { ReactElement } from 'react';

// import css styles
import css from '../css/LabelAndValueListItem.module.css';

interface ILabelAndValueListItemProps
{
	label: string;
	iconClass: string;
	value: string | number;
	isFullWidth?: boolean;
	link?: string;
	tooltipText?: string;
}

/**
 * Component to render counter and metrics
 *
 * @param   {ILabelAndValueListItemProps}                  props - input props for the component
 *
 * @returns {ReactElement<ILabelAndValueListItemProps>}    react component
 */

const LabelAndValueListItem = function(props: ILabelAndValueListItemProps): ReactElement<ILabelAndValueListItemProps>
{
	return (
		<li className={props.isFullWidth ? css.listItemFullWidth :  css.listItem}>
			<span className={`${css.itemTitleAndIcon} elementWithTooltip`}>
				<small className={`${props.iconClass} ${css.icon} elementWithTooltip`}/>
				<span className={props.tooltipText && 'toolTip'}>{props.tooltipText}</span>
			</span>
			<span className={css.itemTitleAndIcon}>
				<small className={css.listItemTitle} data-testid='label'>{props.label}</small>
				{props.link
					? <a className={css.listItemDetailsLink} href={props.link} target='_blank' data-testid='value'>{props.value}</a>
					: <span className={css.listItemDetails}>{props.value}</span>
				}
			</span>
		</li>
	);
};

export default LabelAndValueListItem;
