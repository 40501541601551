import OracleConstants from '../constants/OracleConstants';

/**
 * Makes a network request using fetch and retries the request for given number of times for requests failing with specific http error codes
 *
 * @param {string}  url               `   the URL on which a GET request is made
 * @param {number}  maxRetryCount         number of times to retry the request in case of failure
 * @param {number}  responseCodesToRetry  the response codes of the request on which the retries need to be performed
 *
 * @returns {Promise<Response>}           Result of the fetch function call containing the response
 */
export const fetchWithRetry = async function(
	url: string,
	maxRetryCount: number = OracleConstants.DEFAULT_API_RETRY_COUNT,
	responseCodesToRetry: Array<number> = [ OracleConstants.HTTP_RESPONSE_CODES.TIME_OUT ],
): Promise<Response>
{
	// initialize the response and initial retry attempts
	let response: Response | null = null;
	let retryAttempts = 0;
	let lastEncounteredError: unknown = null;

	// attempt to make a network call if retryAttempts are lesser than maxRetryCount
	while(retryAttempts < maxRetryCount)
	{
		// increment the retryAttempts counter
		retryAttempts += 1;

		try
		{
			response = await fetch(
				url,
				// avoid caching the response
				{ cache: 'no-store' },
			);

			// if the response code is not among the ones configured for retry
			if(!responseCodesToRetry.includes(response.status))
			{
				// return the response
				return response;
			}
		}
		catch(error)
		{
			// NOTE: fetch rejects a promise only in case of a network error, not for non 200 http codes

			// log the error
			console.error(error);

			// store the error in the variable so that we can re throw it if all of the  attempts fail
			lastEncounteredError = error;
		}

		console.log(`retry attempt ${retryAttempts} for ${url}`);
	}

	// if the response is not null (i.e. any of the attempts have reached the backend/url), return the response
	if(response)
	{
		return response;
	}

	// if the response is null (i.e. if the preceding if statement is not true),
	// it implies that all of the requests have failed to reach the url/backend
	// in that case, lastEncounteredError would have the last encountered error assigned to it
	// throw the last encountered error
	throw lastEncounteredError;
};
