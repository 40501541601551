import { ReactElement, useEffect } from 'react';

// import css
import css from '../css/AboutPage.module.css';

/**
* Component to render the about page
*
* @returns {ReactElement}
*/
const FaqPage = function(): ReactElement
{
	// callback triggered when the component mounts
	useEffect(() =>
	{
		// set the document title of the page
		document.title = 'Frequently Asked Questions';
	}, []);

	return (
		<main>
			<h1 style={{ margin: 'var(--space-huge)' }} >Frequently Asked Questions</h1>
			<section className={css.section}>
				<h2>What does a price oracle do?</h2>
				<p>The flow of an oracle is:</p>
				<p>1. Get price data.</p>
				<p>
					The operator of a price oracle chooses a data source based on the needs of potential users.
					For example, some users may prefer high sensitivity to very short term price changes, or the opposite, smoothness over time without short term spikes.
					Some may prefer high speed data while others prefer broadly representative data carefully aggregated from multiple sources.
					Ultimately, the price oracle obtains a stream of prices for its desired use-case.
				</p>
				<p>2. Put the price data into a fixed format message that smart contracts can use.</p>
				<p>
					On the BCH mainchain, smart contracts work by creating a transaction like any other.
					For smart contracts that require price data, the price data needs to be injected into the transaction itself.
					The data is injected in the form of a small, efficient, structured message that a smart contract knows how to break up into parts and use.
					A price message includes critical information for the contract such as the price itself, a timestamp and a message sequence.
				</p>
				<p>3. Sign the message with the oracle's private key.</p>
				<p>
					When a price message is injected into a transaction, a good smart contract will also want to verify that the message comes from the source that the creators of the contract originally agreed on.
					The price oracle proves that by cryptographically signing the price message which is then injected into the smart contract transaction along with the price message.
				</p>
				<p>4. Distribute the message and signature so that they can be used in smart contracts.</p>
				<p>
					After creating a price message and signing it, the price oracle still needs to get the data out into the world where contracts can use them.
					As much as possible, it is important for an oracle to distribute messages blindly without knowledge of who is using the messages.
					Besides the need for blindness, messages can be distributed in any number of ways: included as data in public transactions for the world to use, distributed through a public API, through an anonymously paid private API, etc.
				</p>
			</section>
			<section className={css.section}>
				<h2>How do mainchain BCH smart contracts use a price oracle?</h2>
				<p>There are two main players in the story:</p>
				<p>
					{/* TODO: a ul with nested li was ideal here but will take more efforts as li is styled differently at global level
					 * Some re-architecture to apply global styles in a different way is required, maybe something as simple as using a class
					 */}
					&nbsp;&bull;&nbsp;The price oracle, identified by a public key<br/>
					&nbsp;&bull;&nbsp;The counterparties in a BCH mainchain smart contract
				</p>
				<p>
					Gold Oracle, a price oracle, makes it known that they will regularly publish the spot price of Gold Troy Ounce / BCH.
					Gold Oracle then produces and individually signs prices in the form of a sequence of messages.
					Each message includes some important extra information such as a sequence number and timestamp.
				</p>
				<p>
					Alice and Bob, counterparties, want to make a contract that involves Gold and BCH.
					They decide that Gold Oracle is trustworthy and reliable, and therefore they include Gold Oracle's public key in the details when they first enter into their contract.
					When it is time for Alice and Bob to redeem their contract, they take an appropriate message (probably based on timestamp) and include it directly in the redemption transaction.
					The contract verifies the redemption transaction, including the price, timing, Gold Oracles signature, etc.
					If everything is ok, the redemption transaction is allowed onto the BCH network.
				</p>
			</section>
			<section className={css.section}>
				<h2>How do I verify a price message?</h2>
				<p>
					Anyone can do this via the public key of the oracle.
					When the oracle signs a message, the oracle uses its private key. With the paired public key (available to anyone),
					the message can be verified that it did indeed come from the price oracle.
				</p>
				<p>
					We will provide tools to make that easy for anyone to do.
					For now, a developer can do that verification easily using the <a href='https://gitlab.com/GeneralProtocols/priceoracle/library' target='_blank' >Price Oracle Library</a> which includes straightforward verification instructions.
				</p>
			</section>
			<section className={css.section}>
				<h2>What expectations can users have for these price oracles?</h2>
				<p>
					The <a href='https://gitlab.com/GeneralProtocols/priceoracle/specification'>Price Oracle Specification</a> is still being finalized, but you can see that price oracles have very strict expectations about how they behave.
					For example, they must publish price messages in an exact format so that contracts know how to consume the messages ahead of time.
					Price oracles are also expected to publish important metadata such as:
				</p>
				<p>
					&nbsp;&bull;&nbsp;Start time, when users can expect the oracle to start generating price messages reliably<br/>
					&nbsp;&bull;&nbsp;End time, when users can expect the oracle to stop generating price messages reliably<br/>
					&nbsp;&bull;&nbsp;Time between messages
				</p>
				<p>When we leave beta, all of this relevant information will be provided for each oracle.</p>
			</section>
			<section className={css.section}>
				<h2>Will there be more assets with oracles?</h2>
				<p>
					There is no limit to the number or type of price oracles.
					For example, there can be multiple price oracles for a single asset, each with a different publisher, higher or lower sensitivity to volatility (wicks and candles), publication speeds, etc.
					There can also be price oracles for any asset or concept such as a basket of goods or something like the Big Mac index.
				</p>
				<p>For our part, General Protocols will provide more oracles in line with growing demand for AnyHedge contracts.</p>
			</section>
			<section className={css.section}>
				<h2>Can anyone create a price oracle?</h2>
				<p>Yes. All you need is a public-private keypair and the <a href='https://gitlab.com/GeneralProtocols/priceoracle/library' target='_blank' >Price Oracle Library</a>.</p>
				<p>
					The hard part is building trust that you will provide price messages reliably over time without errors.
					In the long term, we believe that well known price data sources will operate their own oracles.
					We are happy to assist any major operations with setting up their own price oracle.
				</p>
			</section>
			<section className={css.section}>
				<h2>Are there any incentives to run an oracle?</h2>
				<p>An operator of a price oracle can provide their service commercially to subscribers through any number of schemes the operator and client may choose. For example, a subscription where subscribers get real-time data while public release is delayed.</p>
				<p>Alternatively, a price oracle could be a free service that builds reputation for the operator's other businesses. Many publicly available information feeds are already provided this way today.</p>
			</section>
		</main>
	);
};

export default FaqPage;
