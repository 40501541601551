// import types
import type IOracleMessageForPresentation from '../interfaces/IParsedOracleMessage';
import type IHexSignedOracleMessage from '../interfaces/IHexSignedOracleMessage';

// import components
import PriceGraph from '../components/PriceGraph';
import MessagesTable from '../components/MessagesTable';
import OracleMetadataContainer from '../components/OracleMetadataContainer';

// import utils
import { Fragment, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import OracleConstants from '../constants/OracleConstants';
import { getOracleMessages, subscribeToNewMessages } from '../utils/apis';
import css from '../css/IndexPage.module.css';
import { formatOracleMessagesForTableRows, getOracleMessagesTableData } from '../utils/format';
import { useParams } from 'react-router-dom';
import { globalStore } from '../state/StateProvider';

/**
 * Component to render the oracle details page of the application
 *
 * @returns {ReactElement}
 */
const OracleDetailsPage = function(): ReactElement
{
	// state variable and function to update the state variable to hold the oracle messages shown in the table
	const [ oracleMessages, setOracleMessages ] = useState<Array<IOracleMessageForPresentation>>([]);

	// stores the reference to the closeSubscription method across multiple re-renders of the component
	// it is null until the subscription for oracle messages has been created
	const closeSubscriptionMethodRef = useRef<Function | null>(null);

	// get the url params
	const urlParams = useParams() as { oraclePublicKey: string };

	// get the app wide global state
	const { state } = useContext(globalStore);

	// extract the metadata from the oracle public key and metadata map for the oracle being viewed
	const metadata = state.oraclePublicKeyAndMetadataMap[urlParams.oraclePublicKey];

	/**
	 * Fetches the messages for the given public key and updates component state to render the messages in table
	 *
	 * @param {string}  publicKey            public key of the selected oracle
	 * @param {number}  attestationScaling   the value by which the oracle price is divided with for presentation purpose
	 */
	const populateMessagesTable = async function(publicKey: string, attestationScaling: number): Promise<void>
	{
		// get the oracle messages to be shown in the UI table
		const filters = { publicKey, count: OracleConstants.ORACLE_MESSAGES_BATCH_COUNT };
		const parsedOracleMessage = await getOracleMessagesTableData(attestationScaling, filters);

		// set the rows to be rendered in the table component in state variable
		setOracleMessages(parsedOracleMessage);
	};

	/**
	 * Callback triggered when a new message arrives via SSE, adds the message to the oracle messages table view
	 *
	 * @param {IHexSignedOracleMessage} newOracleMessage - oracle message object containing the message fields
	 */
	const onNewOracleMessage = async function(newOracleMessage: IHexSignedOracleMessage): Promise<void>
	{
		// format the oracle message in the structure that can be shown in the table
		const rows = await formatOracleMessagesForTableRows([ newOracleMessage ], Number(metadata.ATTESTATION_SCALING));

		// update the oracleMessagesRows state variable with new oracle message row included in the value to show the new message on the UI
		setOracleMessages((currentOracleMessagesRows) =>
		{
			// check if the latest priceSequence is lesser than the new message's price sequence
			// 	as we would add a message on the top of the table
			// 	only if the new message has been generated after latest message that is visible in the UI
			// 	The case where we can get an old message from the backend is when recovery is in progress
			//	We can choose sort the list and add it to the appropriate position but that is overkill as it won't be used majority of times
			if(currentOracleMessagesRows[0].messageSequence < rows[0].messageSequence)
			{
				return rows.concat(currentOracleMessagesRows);
			}

			// return the existing state if there is no change to the oracle messages list in the UI
			return currentOracleMessagesRows;
		});
	};

	/**
	 * Sets the document title (text that appears in the browser tab) to price of the asset being viewed
	 *
	 * @param   {string}     sourceNumeratorUnitCode         the numerator code of the asset
	 * @param   {string}     sourceDenominatorUnitCode       the denominator code of the asset
	 * @param   {string}     scaledPriceByFactor             price of the asset with the attestationScaling factored in
	 */
	const setPageTitle = function(sourceNumeratorUnitCode: string, sourceDenominatorUnitCode: string, scaledPriceByFactor: string): void
	{
		// set document/tab title to price of the asset being viewed
		document.title = `${scaledPriceByFactor} ${sourceNumeratorUnitCode}/${sourceDenominatorUnitCode}`.trim();
	};

	// function called when the component unmounts
	const componentWillUnmount = function(): void
	{
		if(closeSubscriptionMethodRef.current)
		{
			closeSubscriptionMethodRef.current();
		}
	};

	// callback triggered when the component mounts
	useEffect(() =>
	{
		if(!urlParams.oraclePublicKey)
		{
			throw new Error('missing mandatory URL param oraclePublicKey');
		}

		// return the function that is executed before the component is unmounted
		return componentWillUnmount;
	}, []);

	/**
	 * Callback executed when user clicks on load more button inside table component
	 */
	const onClickLoadMore = async function(): Promise<void>
	{
		// infer the next batch's max timestamp (should be lesser than the current batch's least timestamp)
		const lastMessageTimestamp = oracleMessages[oracleMessages.length - 1].messageTimestamp;
		const prevMaxMessageTimestamp = new Date(lastMessageTimestamp).getTime();

		// initialize the minDataSequence param value for price messages (dataSequence of price messages starts from 1)
		const minDataSequenceForPriceMessages = 1;

		// get the oracle messages from the backend for the next batch to be loaded in the table component
		const oracleMessagesBatch = await getOracleMessages({
			publicKey: String(urlParams.oraclePublicKey),
			count: OracleConstants.ORACLE_MESSAGES_BATCH_COUNT,
			maxMessageTimestamp: Number(prevMaxMessageTimestamp) - 1,
			minDataSequence: minDataSequenceForPriceMessages,
		});

		// format the oracle messages in the format that the table component accepts
		const parsedOracleMessagesBatch = await formatOracleMessagesForTableRows(oracleMessagesBatch, Number(metadata.ATTESTATION_SCALING));

		// merge previous message with new messages
		setOracleMessages(oracleMessages.concat(parsedOracleMessagesBatch));
	};

	// callback triggered when properties on which page title is dependent change
	useEffect(() =>
	{
		// set the page title if the source unit code and asset price are loaded and available
		if(metadata?.SOURCE_NUMERATOR_UNIT_CODE && oracleMessages[0]?.scaledPriceByFactor)
		{
			setPageTitle(metadata.SOURCE_NUMERATOR_UNIT_CODE, metadata.SOURCE_DENOMINATOR_UNIT_CODE || '', oracleMessages[0].scaledPriceByFactor);
		}
	}, [ oracleMessages[0], metadata?.SOURCE_NUMERATOR_UNIT_CODE ]);

	// callback triggered when ATTESTATION_SCALING attribute of metadata changes
	// calls functions that are dependent on ATTESTATION_SCALING or that accept callbacks that are dependent on ATTESTATION_SCALING
	useEffect(() =>
	{
		// if the metadata is loaded and attestation scaling is available
		if(metadata?.ATTESTATION_SCALING)
		{
			// populate the messages table
			populateMessagesTable(urlParams.oraclePublicKey, Number(metadata.ATTESTATION_SCALING));

			// create a subscription for new messages using SSE if it doesn't exist
			if(!closeSubscriptionMethodRef.current)
			{
				closeSubscriptionMethodRef.current = subscribeToNewMessages(urlParams.oraclePublicKey, onNewOracleMessage);
			}
		}
	}, [ metadata?.ATTESTATION_SCALING ]);

	// variable to determine if OracleMetadataContainer can be rendered based on availability of compulsory arguments
	const isMetadataContainerVisible = oracleMessages.length > 0 && urlParams.oraclePublicKey;

	return (
		<Fragment>
			<main className={css.contentArticle}>
				{ urlParams.oraclePublicKey && <PriceGraph
					oraclePublicKey={urlParams.oraclePublicKey}
					currentPrice={oracleMessages[0]?.scaledPriceByFactor}
				/>}
				{isMetadataContainerVisible && <OracleMetadataContainer
					selectedOraclePublicKey={urlParams.oraclePublicKey}
					selectedOracleLatestPrice={String(oracleMessages[0].scaledPriceByFactor)}
					selectedOracleLatestMessageTime={String(oracleMessages[0].messageTimestamp)}
				/>}
				{urlParams.oraclePublicKey && <MessagesTable
					oracleMessages={oracleMessages}
					onClickLoadMore={onClickLoadMore}
					oraclePublicKey={urlParams.oraclePublicKey}
				/>}
			</main>
		</Fragment>
	);
};

export default OracleDetailsPage;
