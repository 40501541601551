// import types
import type IOracleMessageForPresentation from '../interfaces/IParsedOracleMessage';

// import utils
import { ReactElement, useContext, useEffect, useState } from 'react';
import { getOracleMessagesTableData } from '../utils/format';
import { useParams } from 'react-router-dom';
import { globalStore } from '../state/StateProvider';

// import components
import MessageMetadataContainer from '../components/MessageMetadataContainer';

// import style
import css from '../css/OracleMessageDetails.module.css';

/**
 * Component to render the oracle message details page
 *
 * @returns {ReactElement}
 */
const OracleMessageDetailsPage = function(): ReactElement
{
	// state variable and function to update the state variable to hold the oracle messages details shown in the UI
	const [ oracleMessage, setOracleMessage ] = useState<IOracleMessageForPresentation | null>(null);

	// get the url params
	const urlParams = useParams<{ oraclePublicKey: string; messageSequence: string }>();

	// get the app wide global state
	const { state } = useContext(globalStore);

	// fetches formatted messages from utils and sets it in state variable for rendering
	const fetchAndRenderMessage = async (publicKey: string, attestationScaling: number): Promise<void> =>
	{
		const messageSequence = Number(urlParams.messageSequence);

		// get the parsed oracle metadata messages details to be shown in the UI
		const filters = { publicKey, count: 1, minMessageSequence: messageSequence, maxMessageSequence: messageSequence };
		const formattedMessages = await getOracleMessagesTableData(attestationScaling, filters);

		setOracleMessage(formattedMessages[0]);
	};

	// callback triggered when the oraclePublicKeyAndMetadataMap changes
	useEffect(() =>
	{
		// fetch and render the message data if oraclePublicKey and oraclePublicKeyAndMetadataMap are present
		if(Object.keys(state.oraclePublicKeyAndMetadataMap).length > 0 && urlParams.oraclePublicKey)
		{
			const attestationScaling = Number(state.oraclePublicKeyAndMetadataMap[urlParams.oraclePublicKey].ATTESTATION_SCALING);
			fetchAndRenderMessage(urlParams.oraclePublicKey, attestationScaling);
		}
	}, [ Object.keys(state.oraclePublicKeyAndMetadataMap).length ]);

	useEffect(() =>
	{
		// set document/tab title
		document.title = `Message ${urlParams.messageSequence} from ${urlParams.oraclePublicKey}`;
	});

	return (
		<main className={css.oracleMessageDetailsContainer}>
			<h1 className={css.messageDetailsHeading}>Oracle Message</h1>
			{oracleMessage && <MessageMetadataContainer
				selectedOraclePublicKey={urlParams.oraclePublicKey as string}
				messageData={oracleMessage}
			/>}
		</main>
	);
};

export default OracleMessageDetailsPage;
