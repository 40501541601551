// Import library for managing bitcoin cash cryptography.
import { instantiateSha256, instantiateSecp256k1, decodePrivateKeyWif, binToHex, hexToBin, flattenBinArray, numberToBinInt32LE, binToNumberInt32LE, parseBytecode, authenticationInstructionsAreMalformed } from '@bitauth/libauth';

// Import temporary utility functions.
import { getPushDataOpcode } from './libauthTemp';

// Import the debug logging facility.
import { debug } from './logger';

// Import protocol constants.
import { OracleProtocol } from './protocol';

// Import interfaces
import { DataMessage, MetadataMessage, OracleMessage, PriceMessage, SignedOracleMessage, ZeroMQMessage } from './interfaces';

// Define re-usable OP_RETURN binary opcode.
// TODO: See if this exist in a convenient form in libauth.
const OP_RETURN = hexToBin('6A');

/**
 * Functions to manage oracle messages.
 */
export class OracleData
{
	/**
	 * Validates that the provided value is an integer number that is safe to use in script.
	 *
	 * @param name            {string}    Name of the value to use in error messages.
	 * @param value           {number}    Value to validate boundaries for.
	 * @param byteLength      {number}    Number of bytes the number will use.
	 * @param allowNegative   {boolean}   If false, throws an error if the value is negative.
	 * @param allowPositive   {boolean}   If false, throws an error if the value is positive.
	 * @param allowZero       {boolean}   If false, throws an error if the value is zero.
	 *
	 * @returns {Promise<void>}
	 * @throws {Error} an error if the provided value is something other than a valid, integer number in the acceptable range.
	 */
	static async validateScriptIntegerBoundaries(name: string, value: number, byteLength: number, allowNegative: boolean = false, allowPositive: boolean = true, allowZero: boolean = false): Promise<void>
	{
		// Throw an error if the provided value is not a number.
		if(typeof value !== 'number')
		{
			throw(new Error(`Provided ${name} is not a number.`));
		}

		// Throw an error if the provided value is not an integer.
		if(value !== Math.floor(value))
		{
			throw(new Error(`Provided ${name} (${value}) is not a valid integer.`));
		}

		// Calculate the largest possible script value given the byteLength.
		const availableBits = (byteLength * 8) - 1;
		const maxScriptValue = ((2 ** availableBits) - 1);

		// Throw an error if the provided value is outside of the signed script data structure.
		if(value >= maxScriptValue)
		{
			throw(new Error(`Provided ${name} (${value}) is number larger than can be safely encoded in ${byteLength} bytes in script.`));
		}

		// Throw an error if we should reject zero, and the provided value is exactly zero.
		if(!allowZero && (value === 0))
		{
			throw(new Error(`Provided ${name} (${value}) is zero.`));
		}

		// Throw an error if we should reject negative values, and the provided value is negative.
		if(!allowNegative && (value < 0))
		{
			throw(new Error(`Provided ${name} (${value}) is negative.`));
		}

		// Throw an error if we should reject negative values, and the provided value is negative.
		if(!allowPositive && (value > 0))
		{
			throw(new Error(`Provided ${name} (${value}) is positive.`));
		}
	}

	/**
	 * Validates that the provided number is a valid message timestamp to use in oracle messages.
	 *
	 * @param messageTimestamp   {number}   Timestamp when data was recorded by the oracle.
	 *
	 * @returns {Promise<void>}
	 * @throws {Error} an error if the provided number is something other than a valid, integer number in the acceptable range.
	 */
	static async validateMessageTimestamp(messageTimestamp: number): Promise<void>
	{
		// Message timestamps are 4 bytes long.
		// NOTE: this restriction will cause problems in 2038, see https://en.wikipedia.org/wiki/Year_2038_problem
		const byteLength = 4;

		// Validate that the provided value is within bounds.
		await this.validateScriptIntegerBoundaries('messageTimestamp', messageTimestamp, byteLength);
	}

	/**
	 * Validates that the provided number is a valid message sequence to use in oracle messages.
	 *
	 * @param messageSequence   {number}   Sequence number for a message in relation to all messages from the same oracle.
	 *
	 * @returns {Promise<void>}
	 * @throws {Error} an error if the provided number is something other than a valid, integer number in the acceptable range.
	 */
	static async validateMessageSequence(messageSequence: number): Promise<void>
	{
		// Message sequences are 4 bytes long.
		const byteLength = 4;

		// Validate that the provided value is within bounds.
		await this.validateScriptIntegerBoundaries('messageSequence', messageSequence, byteLength);
	}

	/**
	 * Validates that the provided number is a valid data sequence or metadata type to use in oracle messages.
	 *
	 * @param dataSequenceOrType   {number}   Sequence number for a data point in relation to other data messages from the same oracle if positive, or metadata type indication if negative.
	 *
	 * @returns {Promise<void>}
	 * @throws {Error} an error if the provided number is something other than a valid, integer number in the acceptable range.
	 */
	static async validateDataSequenceOrType(dataSequenceOrType: number): Promise<void>
	{
		// Data sequence and metadata types are both 4 bytes long.
		const byteLength = 4;

		// Declared a named parameter for legibility.
		const allowNegativeNumbers = true;

		// Validate that the provided value is within bounds.
		await this.validateScriptIntegerBoundaries('dataSequenceOrType', dataSequenceOrType, byteLength, allowNegativeNumbers);
	}

	/**
	 * Validates that the provided number is a valid data sequence to use in oracle messages.
	 *
	 * @param dataSequence   {number}   Sequence number for a data point in relation to other data messages from the same oracle.
	 *
	 * @returns {Promise<void>}
	 * @throws {Error} an error if the provided number is something other than a valid, integer number in the acceptable range.
	 */
	static async validateDataSequence(dataSequence: number): Promise<void>
	{
		// Data sequences are 4 byte long.
		const byteLength = 4;

		// Validate that the provided value is within bounds.
		await this.validateScriptIntegerBoundaries('dataSequence', dataSequence, byteLength);
	}

	/**
	 * Validates that the provided number is a valid metadata type to use in oracle messages.
	 *
	 * @param metadataType   {number}   Metadata type used to interpret the content of a metadata message.
	 *
	 * @returns {Promise<void>}
	 * @throws {Error} an error if the provided number is something other than a valid, integer number in the acceptable range.
	 */
	static async validateMetadataType(metadataType: number): Promise<void>
	{
		// Metadata types 4 bytes long.
		const byteLength = 4;

		// Declared named parameters for legibility.
		const allowNegativeNumbers = true;
		const allowPositiveNumbers = false;

		// Validate that the provided value is within bounds.
		await this.validateScriptIntegerBoundaries('metadataType', metadataType, byteLength, allowNegativeNumbers, allowPositiveNumbers);
	}

	/**
	 * Validates that the provided number is a valid message price to use in oracle messages.
	 *
	 * @param priceValue   {number}   Price of an asset measured in units per Bitcoin Cash.
	 *
	 * @returns {Promise<void>}
	 * @throws {Error} an error if the provided number is something other than a valid, integer number in the acceptable range.
	 */
	static async validateMessagePrice(priceValue: number): Promise<void>
	{
		// Message prices are 4 bytes long.
		const byteLength = 4;

		// Validate that the provided value is within bounds.
		await this.validateScriptIntegerBoundaries('priceValue', priceValue, byteLength);
	}

	/**
	 * Validates the metadata parameters for use in oracle messages.
	 *
	 * @param messageTimestamp     {number}       Timestamp when data was recorded by the oracle.
	 * @param messageSequence      {number}       Sequence number for a message in relation to all messages from the same oracle.
	 * @param dataSequenceOrType   {number}       Sequence number for a data point in relation to other data messages from the same oracle if positive, or metadata type indication if negative.
	 *
	 * @returns {Promise<void>}
	 * @throws {Error} an error if any of the parameters are not valid for use in an oracle message.
	 */
	static async validateMessageParameters(messageTimestamp: number, messageSequence: number, dataSequenceOrType: number): Promise<void>
	{
		// Validate the parameters.
		await this.validateMessageTimestamp(messageTimestamp);
		await this.validateMessageSequence(messageSequence);
		await this.validateDataSequenceOrType(dataSequenceOrType);
	}

	/**
	* Create an Oracle message.
	*
	* @param messageTimestamp     {number}       Timestamp when data was recorded by the oracle.
	* @param messageSequence      {number}       Sequence number for a message in relation to all messages from the same oracle.
	* @param dataSequenceOrType   {number}       Sequence number for a data point in relation to other data messages from the same oracle if positive, or metadata type indication if negative.
	* @param dataContentBin       {Uint8Array}   Binary content of the oracle message.
	*
	* @returns {Promise<Uint8Array>} The crafted OracleMessage as an Uint8Array.
	*/
	static async createOracleMessage(messageTimestamp: number, messageSequence: number, dataSequenceOrType: number, dataContentBin: Uint8Array): Promise<Uint8Array>
	{
		// Validate the provided parameters.
		await this.validateMessageParameters(messageTimestamp, messageSequence, dataSequenceOrType);

		// Convert the parameters to binary form.
		const messageTimestampBin   = numberToBinInt32LE(messageTimestamp);
		const messageSequenceBin    = numberToBinInt32LE(messageSequence);
		const dataSequenceOrTypeBin = numberToBinInt32LE(dataSequenceOrType);

		// Merge the prepared data into a single byte array.
		const message = flattenBinArray([ messageTimestampBin, messageSequenceBin, dataSequenceOrTypeBin, dataContentBin ]);

		// Write message to log.
		debug.action(`Crafted oracle message #${messageSequenceBin}.`);
		debug.object(binToHex(message));

		// Return the message.
		return message;
	}

	/**
	* Create an Oracle data message.
	*
	* @param messageTimestamp   {number}       Timestamp when data was recorded by the oracle.
	* @param messageSequence    {number}       Sequence number for a message in relation to all messages from the same oracle.
	* @param dataSequence       {number}       Sequence number for a data point in relation to other data messages from the same oracle.
	* @param dataContentBin     {Uint8Array}   Binary content of the oracle message.
	*
	* @returns {Promise<Uint8Array>} The crafted OracleMessage as an Uint8Array.
	*/
	static async createDataMessage(messageTimestamp: number, messageSequence: number, dataSequence: number, dataContentBin: Uint8Array): Promise<Uint8Array>
	{
		// Validate the provided dataSequence.
		// NOTE: the remaining parameters will be validated by the createOracleMessage function.
		await this.validateDataSequence(dataSequence);

		// Create and return the oracle data message.
		return this.createOracleMessage(messageTimestamp, messageSequence, dataSequence, dataContentBin);
	}

	/**
	* Create an Oracle metadata message.
	*
	* @param messageTimestamp     {number}       Timestamp when data was recorded by the oracle.
	* @param messageSequence      {number}       Sequence number for a message in relation to all messages from the same oracle.
	* @param metadataType         {number}       Metadata type used to interpret the content of a metadata message.
	* @param metadataContentBin   {Uint8Array}   Binary content of the metadata message.
	*
	* @returns {Promise<Uint8Array>} The crafted OracleMessage as an Uint8Array.
	*/
	static async createMetadataMessage(messageTimestamp: number, messageSequence: number, metadataType: number, metadataContentBin: Uint8Array): Promise<Uint8Array>
	{
		// Validate the provided metadata type indicator.
		// NOTE: the remaining parameters will be validated by the createOracleMessage function.
		await this.validateMetadataType(metadataType);

		// Create and return the oracle data message.
		return this.createOracleMessage(messageTimestamp, messageSequence, metadataType, metadataContentBin);
	}

	/**
	* Create a PriceOracle message.
	*
	* @param messageTimestamp   {number}   Timestamp of when the data was recorded by the oracle.
	* @param messageSequence    {number}   Sequence number for a message in relation to all messages from the same oracle.
	* @param priceSequence      {number}   Sequence number for a price in relation to other price messages by the same oracle.
	* @param priceValue         {number}   Price of an asset measured in units per Bitcoin Cash.
	*
	* @returns {Promise<Uint8Array>} The crafted PriceMessage as an Uint8Array.
	*/
	static async createPriceMessage(messageTimestamp: number, messageSequence: number, priceSequence: number, priceValue: number): Promise<Uint8Array>
	{
		// Validate the provided parameters.
		await this.validateMessagePrice(priceValue);

		// Convert the price to binary form.
		const priceValueBin = numberToBinInt32LE(priceValue);

		// Create and return the oracle message.
		return this.createOracleMessage(messageTimestamp, messageSequence, priceSequence, priceValueBin);
	}

	/**
	* Verifies that an oracle message has the correct structure.
	*
	* @param message          {Uint8Array}   Oracle message to verify, as an Uint8Array.
	* @param expectedLength   {number}       If set, will throw errors if the message does not match the expected length in bytes.
	*
	* @returns {Promise<void>}
	* @throws an error if the message structure is invalid.
	*/
	static async verifyMessageStructure(message: Uint8Array, expectedLength: number = 0): Promise<void>
	{
		// Throw an error if the provided message is of the wrong type.
		if(!(message instanceof Uint8Array))
		{
			throw(new Error(`Failed to parse oracle message, provided message type (${typeof message}) is not an Uint8Array.`));
		}

		// Throw an error if the provided message is too short.
		if(message.byteLength < OracleProtocol.MINIMUM_MESSAGE_LENGTH)
		{
			throw(new Error(`Failed to parse oracle message, provided message size (${message.byteLength}) is not large enough.`));
		}

		// Throw an error if the provided message length does not match the expected message length.
		if((expectedLength > OracleProtocol.MINIMUM_MESSAGE_LENGTH) && (message.byteLength !== expectedLength))
		{
			throw(new Error(`Failed to parse oracle message, provided message size (${message.byteLength}) does not match the expected size of ${expectedLength} bytes.`));
		}
	}

	/**
	* Parse a oracle message into individual parts.
	*
	* @param message   {Uint8Array}   Oracle message to parse, as an Uint8Array.
	*
	* @returns {Promise<OracleMessage>} An object with the following properties: messageTimestamp, messageSequence, dataSequenceOrType, dataContent.
	*/
	static async parseOracleMessage(message: Uint8Array): Promise<OracleMessage>
	{
		// Verify that the provided message has a valid structure.
		await this.verifyMessageStructure(message);

		// Read the message data from the message.
		const messageTimestamp     = binToNumberInt32LE(message.slice(0, 4));
		const messageSequence      = binToNumberInt32LE(message.slice(4, 8));
		const dataSequenceOrType   = binToNumberInt32LE(message.slice(8, 12));
		const dataContent          = message.slice(12);

		// Validate that price message parts has valid formats.
		await this.validateMessageTimestamp(messageTimestamp);
		await this.validateMessageSequence(messageSequence);
		await this.validateDataSequenceOrType(dataSequenceOrType);

		// Assemble the message parts into an object.
		const parsedOracleMessage = { messageTimestamp, messageSequence, dataSequenceOrType, dataContent } as OracleMessage;

		// Write message to log.
		debug.action('Parsed an oracle message.');
		debug.object(parsedOracleMessage);

		// Return the parsed message.
		return parsedOracleMessage;
	}

	/**
	* Parse a data message into individual parts.
	*
	* @param message   {Uint8Array}   Data message to parse, as an Uint8Array.
	*
	* @returns {Promise<DataMessage>} An object with the following properties: messageTimestamp, messageSequence, dataSequence, dataContent.
	*/
	static async parseDataMessage(message: Uint8Array): Promise<DataMessage>
	{
		// Parse the message into individual parts.
		const { messageTimestamp, messageSequence, dataSequenceOrType, dataContent } = await this.parseOracleMessage(message);

		// Verify that the message is a data message.
		await this.validateDataSequence(dataSequenceOrType);

		// Assign the verified data sequence to a name variable.
		const dataSequence = dataSequenceOrType;

		// Return the parsed data message.
		return { messageTimestamp, messageSequence, dataSequence, dataContent };
	}

	/**
	* Parse a metadata message into individual parts.
	*
	* @param message   {Uint8Array}   Metadata message to parse, as an Uint8Array.
	*
	* @returns {Promise<MetadataMessage>} An object with the following properties: messageTimestamp, messageSequence, metadataType, metadataContent.
	*/
	static async parseMetadataMessage(message: Uint8Array): Promise<MetadataMessage>
	{
		// Parse the message into individual parts.
		const { messageTimestamp, messageSequence, dataSequenceOrType, dataContent } = await this.parseOracleMessage(message);

		// Verify that the message is a data message.
		await this.validateMetadataType(dataSequenceOrType);

		// Convert the metadata type and content to usable strings.
		const metadataType    = dataSequenceOrType;
		const metadataContent = await this.getMetadataString(dataContent);

		// Return the parsed data message.
		return { messageTimestamp, messageSequence, metadataType, metadataContent };
	}

	/**
	* Parse a PriceMessage buffer into individual parts.
	*
	* @param message   {Uint8Array}   PriceMessage to parse, as an Uint8Array.
	*
	* @returns {Promise<PriceMessage>} An object with the following properties: messageTimestamp, messageSequence, priceSequence, and priceValue.
	* @throws errors if the message structure or parsed message parts are invalid.
	*/
	static async parsePriceMessage(message: Uint8Array): Promise<PriceMessage>
	{
		// Price messages are 16 bytes.
		const priceMessageLength = 16;

		// Verify that the provided price message has a valid structure.
		await this.verifyMessageStructure(message, priceMessageLength);

		// Parse the oracle message.
		const { messageTimestamp, messageSequence, dataSequence, dataContent } = await this.parseDataMessage(message);

		// Assign the data sequence to a named variable.
		const priceSequence = dataSequence;

		// Parse the message price from the content of the message.
		const priceValue = binToNumberInt32LE(dataContent);

		// Validate that the price itself has a valid format.
		await this.validateMessagePrice(priceValue);

		// Write additional message data to the log.
		debug.object(priceValue);

		// Return the parsed message.
		return { messageTimestamp, messageSequence, priceSequence, priceValue };
	}

	/**
	* Convert a metadata content into a human readable string.
	*
	* @param metadataContentBin   {Uint8Array}   Binary content of the metadata message.
	*
	* @returns {Promise<string>} the metadata content as a human readable string.
	*/
	static async getMetadataString(metadataContentBin: Uint8Array): Promise<string>
	{
		// transform uint8Array to string
		const decodedString = new TextDecoder().decode(metadataContentBin);

		return decodedString;
	}

	/**
	* Sign an Oracle message.
	*
	* @param message         {Uint8Array}   Oracle message to sign.
	* @param privateKeyWIF   {string}       Private key to sign with in WIF format.
	*
	* @returns {Promise<Uint8Array>} A message signature as a Uint8Array.
	*/
	static async signMessage(message: Uint8Array, privateKeyWIF: string): Promise<Uint8Array>
	{
		// Prepare cryptographic instances to use.
		const sha256 = await instantiateSha256();
		const secp256k1 = await instantiateSecp256k1();

		// Decode the private key WIF into a private key.
		const result = decodePrivateKeyWif(sha256, privateKeyWIF);

		// libauth returns an error string on error, so we check if an error has occurred
		if(typeof result === 'string')
		{
			// And we throw the error
			throw(new Error(`Could not decode passed private key wif ${privateKeyWIF} (Reason: ${result})`));
		}

		// If no error has occurred we extract the private key from the result
		const { privateKey } = result;

		// Hash the message.
		const messageHash = sha256.hash(message);

		// Sign the message.
		const signature = secp256k1.signMessageHashSchnorr(privateKey, messageHash);

		// Write message to log.
		debug.action('Signed an oracle message.');
		debug.object(binToHex(signature));

		// Return the signature.
		return signature;
	}

	/**
	* Verify if a message signature is valid for a given message.
	*
	* @param message     {Uint8Array}   Oracle message to test against, as an Uint8Array.
	* @param signature   {Uint8Array}   Message signature to verify, as an Uint8Array.
	* @param publicKey   {Uint8Array}   Public key for the signature, as an Uint8Array.
	*
	* @returns {Promise<boolean>} True if the message, signature and public key is valid, otherwise false.
	*/
	static async verifyMessageSignature(message: Uint8Array, signature: Uint8Array, publicKey: Uint8Array): Promise<boolean>
	{
		try
		{
			// Prepare cryptographic instances to use.
			const sha256 = await instantiateSha256();
			const secp256k1 = await instantiateSecp256k1();

			// Hash the message.
			const messageHash = sha256.hash(message);

			// Verify the signature.
			const validity = secp256k1.verifySignatureSchnorr(signature, publicKey, messageHash);

			// Write message to log.
			debug.action(`Verified an oracle message signature with validity: ${validity.toString()}.`);

			// Return the validity of the message signature.
			return validity;
		}
		catch(error)
		{
			// Write message to log.
			debug.errors(`Failed to verify an oracle message signature: ${error}`);

			// Return false to indicate failure.
			return false;
		}
	}

	/**
	 * Assembles a packet topic for use in distribution.
	 *
	* @returns {Promise<Uint8Array>} the message topic as an Uint8Array.
	 */
	static async createPacketTopic(): Promise<Uint8Array>
	{
		// Assemble a binary packet topic with the appropriate message type.
		const messageTopic = flattenBinArray([ OP_RETURN, getPushDataOpcode(4), OracleProtocol.IDENTIFIER ]);

		// return the assembled topic.
		return messageTopic;
	}

	/**
	 * Assembles packet content to use in distribution.
	 *
	 * @param message     {Uint8Array}   Oracle message to distribute, as an Uint8Array.
	 * @param publicKey   {Uint8Array}   Public key to distribute, as an Uint8Array.
	 * @param signature   {Uint8Array}   Message signature to distribute, as an Uint8Array.
	 *
	 * @returns {Promise<Uint8Array>}
	 */
	static async createPacketContent(message: Uint8Array, publicKey: Uint8Array, signature: Uint8Array): Promise<Uint8Array>
	{
		// Assemble the packet content.
		return flattenBinArray([ getPushDataOpcode(message.length), message, getPushDataOpcode(publicKey.length), publicKey, getPushDataOpcode(signature.length), signature ]);
	}

	/**
	* Packs up a signed oracle message for distribution.
	*
	* The format used is compatible with both ZeroMQ and OP_RETURN based distribution.
	* When used with OP_RETURN, concatenate the returned the topic and content.
	*
	* @param message     {Uint8Array}   Oracle message to distribute, as an Uint8Array.
	* @param signature   {Uint8Array}   Message signature to distribute, as an Uint8Array.
	* @param publicKey   {Uint8Array}   Public key to distribute, as an Uint8Array.
	*
	* @returns {Promise<ZeroMQMessage>} A tuple with the binary topic and content for distribution.
	*/
	static async packOracleMessage(message: Uint8Array, signature: Uint8Array, publicKey: Uint8Array): Promise<ZeroMQMessage>
	{
		// Create the topic and message parts separately.
		const binaryTopic = await this.createPacketTopic();
		const binaryContent = await this.createPacketContent(message, publicKey, signature);

		// Write message to log.
		debug.action('Packed an oracle message for distribution.');
		debug.object([ binaryTopic, binaryContent ]);

		// Return the topic and message.
		return [ binaryTopic, binaryContent ];
	}

	/**
	* Unpacks a signed oracle message from a distributed package.
	*
	* @param binaryPackage   {Uint8Array}   packed signed oracle message to parse, as an Uint8Array.
	*
	* @returns {Promise<SignedOracleMessage | false>} An object with the following properties: message, public key, signature, or false if unable to unpack the oracle message.
	*/
	static async unpackOracleMessage(binaryPackage: Uint8Array): Promise<SignedOracleMessage | false>
	{
		// Get a valid topic for oracle messages.
		const validTopic = await this.createPacketTopic();

		// Read the package topic.
		const binaryTopic = binaryPackage.slice(0, validTopic.byteLength);

		// Validate that the package topic is correct.
		if(binToHex(binaryTopic) !== binToHex(validTopic))
		{
			// Write message to log.
			debug.errors('Failed to unpack message: invalid package topic.');

			// Return false to indicate failure.
			return false;
		}

		// Split the packet into parts.
		// NOTE: The packet is valid bytecode, which lets us use libauth to separate the parts.
		const parsedBytecode = parseBytecode(binaryPackage.slice(validTopic.byteLength));

		// Throw an error if the packet was not properly formed.
		if(authenticationInstructionsAreMalformed(parsedBytecode))
		{
			throw(new Error('Failed to unpack message: invalid structure.'));
		}

		if(!('data' in parsedBytecode[0]))
		{
			throw(new Error('Failed to unpack message: message content was not pushed properly.'));
		}

		if(!('data' in parsedBytecode[1]))
		{
			throw(new Error('Failed to unpack message: public key was not pushed properly.'));
		}

		if(!('data' in parsedBytecode[2]))
		{
			throw(new Error('Failed to unpack message: signature was not pushed properly.'));
		}

		// Read the content data from the package.
		// NOTE: This skips over the lengths/push bytes.
		const message   = parsedBytecode[0].data;
		const publicKey = parsedBytecode[1].data;
		const signature = parsedBytecode[2].data;

		// Assemble the signed oracle message from the content.
		const data = { message, publicKey, signature } as SignedOracleMessage;

		// Write message to log.
		debug.action('Unpacked a distributed oracle message.');
		debug.object(data);

		// Return the parsed data.
		return data;
	}
}
