// import types
import type IOracleSummary from '../interfaces/IOracleSummary';
import type ILatestMessageAndRecoveryProgress from '../interfaces/ILatestMessageAndRecoveryProgress';

// import utils
import { Fragment, ReactElement, useEffect, useState, useRef, useContext } from 'react';
import { getPublicKeyAndPriceMessageWithRecoveryProgressMap, prepareOracleSummaries, preparePublicKeyAndOraclesDataMap, preparePublicKeyAndChangePercentageMap } from '../utils/format';
import { globalStore } from '../state/StateProvider';

// import components
import OracleSummaries from '../components/OracleSummaries';

/**
 * Component to render the index page of the application
 *
 * @returns {ReactElement}
 */
const IndexPage = function(): ReactElement
{
	// get the app wide global state
	const { state } = useContext(globalStore);

	// initialize the interval in milliseconds (1 minute) to refresh the oracles summary on the page
	const summaryRefreshIntervalInMilliseconds = 60000;

	// stores the reference to the time interval across multiple re-renders of the component
	const intervalRef = useRef<number>();

	// stores and sets the oracle summary data in state
	const [ oracleSummariesData, setOracleSummariesData ] = useState<Array<IOracleSummary>>([]);

	// state variables to store various parts of oracle summary data
	const [ publicKeyAndPriceChangeInfoMap, setPublicKeyAndPriceChangeInfoMap ] = useState<Record<string, number>>({});
	const [
		latestPriceMessageAndRecoveryProgress,
		setLatestPriceMessageAndRecoveryProgress,
	] = useState<Record<string, ILatestMessageAndRecoveryProgress>>({});

	const reloadSummaries = function(oraclePublicKeys: Array<string>): void
	{
		preparePublicKeyAndOraclesDataMap()
			.then((publicKeyAndOraclesDataMap) =>
			{
				const publicKeyAndChangePercentageMap = preparePublicKeyAndChangePercentageMap(publicKeyAndOraclesDataMap);
				setPublicKeyAndPriceChangeInfoMap(publicKeyAndChangePercentageMap);
			});

		getPublicKeyAndPriceMessageWithRecoveryProgressMap(oraclePublicKeys)
			.then((publicKeyAndLatestMessageWithRecoveryProgressMap: Record<string, ILatestMessageAndRecoveryProgress>) =>
			{
				setLatestPriceMessageAndRecoveryProgress(publicKeyAndLatestMessageWithRecoveryProgressMap);
			});
	};

	/**
	 * Callback triggered when the component unmounts.
	 */
	const onComponentWillUnmount = function(): void
	{
		// clear the interval set up in onComponentMount callback
		clearInterval(intervalRef.current);
	};

	/**
	 * Callback triggered when the component mounts. Sets up the initial state for the UI
	 */
	const onComponentMount = function(): void
	{
		document.title = 'Price Oracles for Bitcoin Cash';
	};

	// sets up the callbacks for component mount and unmount lifecycle stages of the component
	useEffect(() =>
	{
		onComponentMount();

		return onComponentWillUnmount;
	}, []);

	// callback triggered when any of the data that is used to render the table changes
	useEffect(() =>
	{
		if(Object.keys(state.oraclePublicKeyAndMetadataMap).length > 0)
		{
			// prepare the oracle summaries using the dependent data, all of the arguments can have default values
			prepareOracleSummaries(state.oraclePublicKeyAndMetadataMap).then((summaries) => setOracleSummariesData(summaries));
		}
	}, [
		// watch only the length of oraclePublicKeyAndMetadataMap keys as it is loaded only once in the app lifecycle
		Object.keys(state.oraclePublicKeyAndMetadataMap).length,
	]);

	return (
		<Fragment>
			<main>
				{oracleSummariesData.length > 0 && <OracleSummaries summary={oracleSummariesData}/>}
				{/* <Metrics metrics={relayMetrics} heading='Relay Metrics'/> */}
			</main>
		</Fragment>
	);
};

export default IndexPage;
