// import utils
import { ReactElement, useContext } from 'react';
import { globalStore } from '../state/StateProvider';

// import css
import '../css/Footer.module.css';

// import images
import generalProtocolsLogo from '../assets/generalProtocolsLogo.png';

/**
 * Footer that appears at the bottom of each page
 *
 * @returns {ReactElement<IMessagesTableProps>}  react component
 */
const Footer = function(): ReactElement
{
	// get the app wide global state
	const { state } = useContext(globalStore);

	// get the count of oracles
	const oracleCount = Object.keys(state.oraclePublicKeyAndMetadataMap).length;

	return <footer>
		<section>
			<h4>Links</h4>
			<ul>
				<li><a href='/about'>About Oracles.Cash</a></li>
				<li><a href='/faq' target='_blank'>Frequently Asked Questions</a></li>
				<li><a href='https://generalprotocols.com/' target='_blank'>General Protocols</a></li>
			</ul>
		</section>
		<section>
			<h4>Stats</h4>
			<ul>
				<li><strong>{oracleCount}</strong> Oracles</li>
				{/* <li><strong>{state.oracleMessageCount}</strong> Messages</li> */}
			</ul>
		</section>
		<section>
			{/* <h4>Development</h4>
			<ul>
				<li><a href='/'>Specification</a></li>
				<li><a href='/'>API</a></li>
			</ul> */}
		</section>
		<section>
			{/* <h4>Status</h4>
			<ul>
				<li>
					<a href='/'>Relay statistics</a>
				</li>
			</ul> */}
		</section>
		<section>
			<a href='https://generalprotocols.com/' target='_blank' style={{ textAlign: 'center' }}>
				<img src={generalProtocolsLogo} />
			</a>
		</section>
	</footer>;
};

export default Footer;
