// import interfaces
import type IAction from '../interfaces/IAction';
import type IOracleWithMessageMetrics from '../interfaces/IOracleWithMessageMetrics';
import type IOracleMetadata from '../interfaces/IOracleMetadata';

import { GET_ORACLES_METADATA_SUCCESS, GET_ORACLES_SUCCESS, GET_ORACLES_MESSAGE_COUNT_SUCCESS } from './types';

export const getOraclesSuccessAction = function(oracles: Array<IOracleWithMessageMetrics>): IAction
{
	return {
		type: GET_ORACLES_SUCCESS,
		payload: {
			oracles,
		},
	};
};

export const getOraclePublicKeyAndMetadataMapSuccessAction = function(oraclePublicKeyAndMetadataMap: Record<string, IOracleMetadata>): IAction
{
	return {
		type: GET_ORACLES_METADATA_SUCCESS,
		payload: {
			oraclePublicKeyAndMetadataMap,
		},
	};
};

export const getOracleMessageCountSuccessAction = function(messageCount: number): IAction
{
	return {
		type: GET_ORACLES_MESSAGE_COUNT_SUCCESS,
		payload: {
			messageCount,
		},
	};
};
