// import types
import type IGetOracleMessagesFilters from '../interfaces/IGetOracleMessagesFilters';
import type IOracleWithMessageMetrics from '../interfaces/IOracleWithMessageMetrics';
import type IRawPriceGraphDataPoint from '../interfaces/IRawPriceGraphDataPoint';
import type IRelayStatus from '../interfaces/IRelayStatus';
import type IHexSignedOracleMessage from '../interfaces/IHexSignedOracleMessage';
import type IOnNewPriceMessageCallback from '../interfaces/IOnNewPriceMessageCallback';
import type IRecoveryProgress from '../interfaces/IRecoveryProgress';

// import utils
import { fetchWithRetry } from './request';

export const cleanGetOraclesResponse = async function(responseData: any): Promise<Array<IOracleWithMessageMetrics>>
{
	// Get a typed structure
	const oracles = responseData.oracles as Array<IOracleWithMessageMetrics>;

	// validate the overall response. Throw if invalid
	if(typeof oracles === 'undefined')
	{
		throw(new Error('oracle metrics: response did not contain list of oracle metrics'));
	}

	// TODO: Ideally we validate the complete structure of each entry, at least for data safety

	// filter valid response entries. Log if invalid
	const validEntries: Array<IOracleWithMessageMetrics> = [];
	for(const oracle of oracles)
	{
		// skip and log if undefined public key
		if(typeof oracle.publicKey === 'undefined')
		{
			console.warn(`oracle metrics: skipping oracle (id ${oracle.oracleId}) with undefined public key`);
			continue;
		}

		// skip and log if undefined metrics
		// Note that this is not validating the whole metrics structure or content
		if(typeof oracle.messageMetrics === 'undefined')
		{
			console.warn(`oracle metrics: skipping oracle (public key ${oracle.publicKey}) with undefined metrics`);
			continue;
		}

		// include this entry as valid
		validEntries.push(oracle);
	}

	// Return all the valid oracles we found
	return validEntries;
};

/**
 * fetches oracles from the backend
 *
 * @returns {Promise<Array<IOracleWithMessageMetrics>>}   list of oracles with message metrics
 *
 * @throws  {Error}                                       if API call fails for any reason
 */
export const getOracles = async function(): Promise<Array<IOracleWithMessageMetrics>>
{
	// Construct the URL we will fetch data from.
	const url = new URL(`${process.env.REACT_APP_RELAY_BASE_URL}/api/v1/oracles`);

	// Fetch the data from the server.
	const response = await fetchWithRetry(url.href);

	if(response.status === 200 && response)
	{
		const responseData = await response.json();

		// clean the data, throwing if overall response is invalid
		const cleanData = cleanGetOraclesResponse(responseData);

		return cleanData;
	}

	throw(new Error('Error while fetching oracles'));
};

export const cleanGetOracleMesagesResponse = async function(responseData: any): Promise<Array<IHexSignedOracleMessage>>
{
	// Get a typed structure
	const oracleMessages = responseData.oracleMessages as Array<IHexSignedOracleMessage>;

	// validate the overall response. Throw if invalid
	if(typeof oracleMessages === 'undefined')
	{
		throw(new Error('oracle messages: response did not contain list of oracle messages'));
	}

	// TODO: Ideally we validate the complete structure of each entry, at least for data safety

	// For now, just return the messages as-is
	return oracleMessages;
};

/**
 * fetches oracle messages from the backend
 *
 * @param   {IGetOracleMessagesFilters}                 filters - object containing filter and attributes to get oracle messages
 *
 * @returns {Promise<Array<IHexSignedOracleMessage>>}   array of oracle message objects
 *
 * @throws  {Error}                                     if API call fails for any reason
 */
export const getOracleMessages = async function(filters: IGetOracleMessagesFilters): Promise<Array<IHexSignedOracleMessage>>
{
	// Construct the URL we will fetch data from.
	const url = new URL(`${process.env.REACT_APP_RELAY_BASE_URL}/api/v1/oracleMessages`);

	// Add parameters to filter the messages.
	for(const index in filters)
	{
		// @ts-ignore
		if(filters[index])
		{
			// @ts-ignore
			url.searchParams.append(index, filters[index]);
		}
	}

	// Fetch the data from the server.
	const response = await fetchWithRetry(url.toString());

	// Return the data if successful.
	if(response.status === 200)
	{
		const responseData = await response.json();

		// clean the data, throwing if overall response is invalid
		const cleanData = cleanGetOracleMesagesResponse(responseData);

		return cleanData;
	}

	throw(new Error('Error while fetching oraclesMessages'));
};

export const cleanGetRelayStatusResponse = async function(responseData: any): Promise<IRelayStatus>
{
	// Get a typed structure
	const relayStatus = responseData.relayStatus as IRelayStatus;

	// validate the overall response. Throw if invalid
	if(typeof relayStatus === 'undefined')
	{
		throw(new Error('relay status: response did not contain relay status'));
	}

	// TODO: Ideally we validate the complete structure, at least for data safety

	// For now, we return the structure as-is
	return relayStatus;
};

/**
 * Fetches counters and metrics from the backend
 *
 * @returns {Promise<IRelayStatus>}   list counter and metrics for oracle
 *
 * @throws  {Error}                   if API call fails for any reason
 */
export const getRelayStatus = async function(): Promise<IRelayStatus>
{
	// Construct the URL we will fetch data from.
	const url = new URL(`${process.env.REACT_APP_RELAY_BASE_URL}/api/v1/relayStatus`);

	// Fetch the data from the server.
	const response = await fetchWithRetry(url.toString());

	// Return the data if successful.
	if(response.status === 200)
	{
		const responseData = await response.json();

		// clean the data, throwing if overall response is invalid
		const cleanData = cleanGetRelayStatusResponse(responseData);

		return cleanData;
	}

	throw(new Error('Error while fetching relayStatus'));
};

export const cleanGetPriceGraphDataResponse = async function(responseData: any): Promise<Array<IRawPriceGraphDataPoint>>
{
	// Get a typed structure
	const priceGraphPoints = responseData.priceGraphPoints as Array<IRawPriceGraphDataPoint>;

	// validate the overall response. Throw if invalid
	if(typeof priceGraphPoints === 'undefined')
	{
		throw(new Error('price graph data: response did not contain list of price graph data points'));
	}

	// TODO: Ideally we validate the complete structure of each entry, at least for data safety

	// For now, just return the data points as-is
	return priceGraphPoints;
};

/**
 * Fetches the price graph data from the backend
 *
 * @param   {string}    publicKey               public key of the oracle for which graph data is to be fetched
 * @param   {number}    minMessageTimestamp     the minimum timestamp from which price messages are used for graph data
 * @param   {number}    maxMessageTimestamp     the maximum timestamp upto which price messages are used for graph data
 * @param   {string}    aggregationPeriod       indicates the time period for which the max, min and avg data points are aggregated
 *
 * @returns {Promise<Array<IRawPriceGraphDataPoint>>}   list counter and metrics for oracle
 *
 * @throws  {Error}                             if API call fails for any reason
 */
export const getPriceGraphData = async function(
	publicKey: string,
	minMessageTimestamp: number,
	maxMessageTimestamp: number,
	aggregationPeriod: string,
): Promise<Array<IRawPriceGraphDataPoint>>
{
	// Construct the URL we will fetch data from.
	const url = new URL(`${process.env.REACT_APP_RELAY_BASE_URL}/api/v2/priceGraphPoints`);

	// Add parameters to filter the graph price points.
	url.searchParams.append('publicKey', publicKey.toString());
	url.searchParams.append('minMessageTimestamp', minMessageTimestamp.toString());
	url.searchParams.append('maxMessageTimestamp', maxMessageTimestamp.toString());
	url.searchParams.append('aggregationPeriod', aggregationPeriod);

	// Fetch the data from the server.
	const response = await fetchWithRetry(url.toString());

	// Return the data if successful.
	if(response.status === 200)
	{
		const responseData = await response.json();

		// clean the data, throwing if overall response is invalid
		const cleanData = cleanGetPriceGraphDataResponse(responseData);

		return cleanData;
	}

	throw(new Error('Error while fetching PriceGraphData'));
};

/**
 * Creates a subscription for consume newly generated oracle messages with the backend using SSE
 *
 * @param    {string}                       publicKey             public key of the oracle for which the new messages are subscribed
 * @param    {IOnNewPriceMessageCallback}   onNewPriceMessage     callback that is triggered when a new message arrives via subscription
 *
 * @returns  {{ (): void }}                 function to close the subscription
 *
 * @throws   {Error}                        if the browser doesn't support SSE/EventSource
 */
export const subscribeToNewMessages = (publicKey: string, onNewPriceMessage: IOnNewPriceMessageCallback): { (): void } =>
{
	// if EventSource is not supported by the browser
	if(!window.EventSource)
	{
		// throw error explaining the cause of failure
		throw new Error('Your browser doesn\'t support event source');
	}

	const source = new EventSource(`${process.env.REACT_APP_RELAY_BASE_URL}/sse/v1/messages`);

	// listen to the event with name as public key
	source.addEventListener(publicKey, (event) =>
	{
		try
		{
			const eventData = JSON.parse(event.data);

			// prepare a message of type IHexSignedOracleMessage for the callback argument
			const signedMessage = { message: eventData.message, signature: eventData.signature, publicKey };

			onNewPriceMessage(signedMessage);
		}
		catch(error)
		{
			console.log({ error });
		}
	});

	source.onopen = () =>
	{
		// log an action saying that SSE connection is set up
		console.log('SSE connection opened');
	};

	source.onerror = () =>
	{
		// log the error
		console.log('Error in SSE connection');
		source.close();
	};

	// function to close the SSE connection
	const closeSubscription = (): void =>
	{
		source.close();
	};

	return closeSubscription;
};

export const cleanGetOracleMetadataResponse = async function(responseData: any): Promise<Array<IHexSignedOracleMessage>>
{
	// Get a typed structure
	const oracleMetadata = responseData.oracleMetadata as Array<IHexSignedOracleMessage>;

	// validate the overall response. Throw if invalid
	if(typeof oracleMetadata === 'undefined')
	{
		throw(new Error('oracle metadata: response did not contain list of messages'));
	}

	// TODO: Ideally we validate the complete structure of each entry, at least for data safety

	// For now, just return the messages as-is
	return oracleMetadata;
};

/**
 * fetches oracles metadata from the backend
 *
 * @returns {Promise<Array<IHexSignedOracleMessage>>}   list of oracles metadata
 *
 * @throws  {Error}                                     if API call fails for any reason
 */
export const getOracleMetadata = async function(): Promise<Array<IHexSignedOracleMessage>>
{
	// Construct the URL we will fetch data from.
	const url = new URL(`${process.env.REACT_APP_RELAY_BASE_URL}/api/v1/oracleMetadata`);

	// Add parameters to filter the metadata of large count param value
	url.searchParams.append('count', '999999999');

	// Fetch the data from the server.
	const response = await fetchWithRetry(url.toString());

	// Return the data if successful.
	if(response.status === 200)
	{
		const responseData = await response.json();

		// clean the data, throwing if overall response is invalid
		const cleanData = cleanGetOracleMetadataResponse(responseData);

		return cleanData;
	}

	throw(new Error('Error while fetching oracles metadata'));
};

export const cleanGetRecoveryProgressResponse = async function(responseData: any): Promise<IRecoveryProgress>
{
	// Get a typed structure
	const recoveryProgress = responseData.recoveryProgress as IRecoveryProgress;

	// validate the overall response. Throw if invalid
	if(typeof recoveryProgress === 'undefined')
	{
		throw(new Error('recovery progress: response did not contain recovery progress data'));
	}

	// TODO: Ideally we validate the complete structure, at least for data safety

	// For now, we return the structure as-is
	return recoveryProgress;
};

/**
 * fetches recovery progress from the backend based on the public key
 *
 * @param   {string}                        publicKey  public key of the oracle
 *
 * @returns {Promise<IRecoveryProgress>}    object of recovery progress for publicKey
 *
 * @throws  {Error}                         if API call fails for any reason
 */
export const getRecoveryProgress = async function(publicKey: string): Promise<IRecoveryProgress>
{
	// Construct the URL we will fetch data from.
	const url = new URL(`${process.env.REACT_APP_RELAY_BASE_URL}/api/v1/recoveryProgress`);

	// Add parameters to filter the recovery progress
	url.searchParams.append('publicKey', publicKey);

	// Fetch the data from the server.
	const response = await fetchWithRetry(url.toString());

	// Return the data if successful.
	if(response.status === 200)
	{
		const responseData = await response.json();

		// clean the data, throwing if overall response is invalid
		const cleanData = cleanGetRecoveryProgressResponse(responseData);

		return cleanData;
	}

	throw(new Error('Error while fetching recovery progress'));
};
