// import utils
import { ReactElement, useContext, useEffect, useState } from 'react';
import { getRecoveryProgress } from '../utils/apis';
import { getRecoveryProgressPresentationAttributes } from '../utils/format';
import { globalStore } from '../state/StateProvider';

// import constants
import { OracleProtocol } from '../utils/priceOracle/protocol';

// import css
import css from '../css/OracleMetadataContainer.module.css';

// import components
import LabelAndValueListItem from './LabelAndValueListItem';

// import types
import type IRecoveryProgressPresentation from '../interfaces/IRecoveryProgressPresentation';
import type IRecoveryProgress from '../interfaces/IRecoveryProgress';

interface IOracleMetadataContainerProps
{
	selectedOraclePublicKey: string;
	selectedOracleLatestPrice: string;
	selectedOracleLatestMessageTime: string;
}

/**
 * Component to render counter and metrics
 *
 * @param   {IOracleMetadataContainerProps}                  props - input props for the component
 *
 * @returns {ReactElement<IOracleMetadataContainerProps>}    react component
 */
const OracleMetadataContainer = function(props: IOracleMetadataContainerProps): ReactElement<IOracleMetadataContainerProps>
{
	// app wide global state and function to dispatch actions that result in state change via reducers
	const { state } = useContext(globalStore);

	// get the dependencies required to prepare OracleMetaInfo items
	const {
		STARTING_TIMESTAMP,
		ENDING_TIMESTAMP,
		SOURCE_NAME,
		SOURCE_WEBSITE,
		SOURCE_NUMERATOR_UNIT_NAME,
		SOURCE_NUMERATOR_UNIT_CODE,
		SOURCE_DENOMINATOR_UNIT_NAME,
		SOURCE_DENOMINATOR_UNIT_CODE,
		OPERATOR_NAME,
		OPERATOR_WEBSITE,
		ATTESTATION_PERIOD,
	} = state.oraclePublicKeyAndMetadataMap[props.selectedOraclePublicKey] || {};

	const [ recoveryProgressPresentation, setRecoveryProgressPresentation ] = useState<IRecoveryProgressPresentation>();

	const onComponentMount = async function(): Promise<void>
	{
		const recoveryProgress: IRecoveryProgress = await getRecoveryProgress(props.selectedOraclePublicKey);
		const recoveryProgressPresentationAttributes = getRecoveryProgressPresentationAttributes(recoveryProgress);

		setRecoveryProgressPresentation(recoveryProgressPresentationAttributes);
	};

	// call the functions to set up the initial state when the component mounts
	useEffect(() =>
	{
		onComponentMount();
	}, []);

	return (
		<section className={css.metadataWidgetContainer}>
			<h2 className={css.metadataHeading}>
				Oracle details
				{recoveryProgressPresentation && <span
					style={{ color: recoveryProgressPresentation.iconColor, marginLeft: '1ex' }}
					className={recoveryProgressPresentation.icon}
				/>}
			</h2>
			<ul className={css.oracleMetadataList}>
				{SOURCE_NUMERATOR_UNIT_NAME && SOURCE_NUMERATOR_UNIT_CODE && <LabelAndValueListItem
					label='Asset'
					value={`${SOURCE_NUMERATOR_UNIT_NAME} (${SOURCE_NUMERATOR_UNIT_CODE}) / ${SOURCE_DENOMINATOR_UNIT_NAME} (${SOURCE_DENOMINATOR_UNIT_CODE})`}
					iconClass={'--oracle'}
					tooltipText={OracleProtocol.METADATA_TYPES['-54'].description}
				/>}
				{ATTESTATION_PERIOD && <LabelAndValueListItem
					label='Period'
					value={`${Number(ATTESTATION_PERIOD).toLocaleString()} milliseconds`}
					iconClass='--period'
					tooltipText={OracleProtocol.METADATA_TYPES['-7'].description}
				/>}
				{OPERATOR_NAME && <LabelAndValueListItem
					label='Operator'
					value={OPERATOR_NAME}
					link={OPERATOR_WEBSITE}
					iconClass='--operator'
					tooltipText={OracleProtocol.METADATA_TYPES['-1'].description}
				/>}
				{SOURCE_NAME && <LabelAndValueListItem
					label='Source'
					value={SOURCE_NAME}
					link={SOURCE_WEBSITE}
					iconClass='--source'
					tooltipText={OracleProtocol.METADATA_TYPES['-51'].description}
				/>}
				{STARTING_TIMESTAMP && <LabelAndValueListItem
					label='Commitment Start'
					value={`${new Date(Math.round(Number(STARTING_TIMESTAMP) * 1000)).toISOString()}`}
					iconClass='--clock'
					tooltipText={OracleProtocol.METADATA_TYPES['-4'].description}
				/>}
				{ENDING_TIMESTAMP && <LabelAndValueListItem
					label='Commitment End'
					value={`${new Date(Math.round(Number(ENDING_TIMESTAMP) * 1000)).toISOString()}`}
					iconClass='--clock'
					tooltipText={OracleProtocol.METADATA_TYPES['-5'].description}
				/>}
				<LabelAndValueListItem
					label='Public Key'
					value={props.selectedOraclePublicKey}
					iconClass='--public-key'
					tooltipText='Hex representation of the public key linked to the message'
				/>
			</ul>
		</section>
	);
};

export default OracleMetadataContainer;
